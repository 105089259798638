import clsx from 'clsx'
import {useLayout} from '../../core'
import {toAbsoluteUrl} from '../../../helpers'
import {Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const MobileFooter = () => {
  const {config} = useLayout()
  if (!config.app?.footer?.display) {
    return null
  }

  return (
    <div className='d-flex d-md-none py-2' id='ilead_app_mobile_footer'>
      <Link
        to='/directory'
        className='w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center'
      >
        <i className='bi bi-people fs-2x text-dark mb-1'></i>
        Directory
      </Link>
      <Link
        to='/tyca'
        className='w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center'
      >
        <i className='bi bi-card-checklist fs-2x text-dark mb-1'></i>
        TYCA
      </Link>
      <Link
        to='/dashboard'
        className='w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center'
      >
        <Image src={toAbsoluteUrl('/media/ilead/home1.png')} fluid className='w-50px'></Image>
      </Link>
      <Link
        to='/index'
        className='w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center'
      >
        <i className='bi bi-graph-up-arrow fs-2x text-dark mb-1'></i>
        Index
      </Link>
      <Link
        to='/chat'
        className='w-20 flex-1 text-center text-dark d-flex flex-column justify-content-center align-items-center'
      >
        <i className='bi bi-chat-dots fs-2x text-dark mb-1'></i>
        Chat
      </Link>
    </div>
  )
}

export {MobileFooter}
