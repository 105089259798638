import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Image } from 'react-bootstrap'
// import ReactAudioPlayer from 'react-audio-player'

const Library: React.FC = () => {
  return (
    <>
      <div className='row '>
        <div className='col-md-6  px-4'>
          <h1 className='inv-hst'>Library</h1>
        </div>

        <div className='col-md-6 d-flex flex-column flex-end library-filter  px-4'>
          <div className='filter-div'>
            <h4> Filter By Category </h4>
            <select className='form-select form-select-sm' aria-label='.form-select-sm example'>
              <option selected>All</option>
              <option value='1'>ILEAD Surkhiya</option>
              <option value='2'>ILEAD Sampark Presentation</option>
              <option value='3'>Find Out Coach!</option>
              <option value='4'>CHATBOX WISDOM of 08-01</option>
              <option value='5'>Build India</option>
              <option value='6'>Brochure</option>
              <option value='7'>TYCA</option>
              <option value='8'>VI Session</option>
              <option value='9'>VA Session</option>
              <option value='10'>Nagative Changes by Paresh Kariya</option>
              <option value='11'>Converting Crisis into Opportunity</option>
              <option value='12'>The Art of Delegation</option>
              <option value='13'>Sales Engine - Part || </option>
              <option value='14'>Dialogue with Coach Shyam Taneja</option>
              <option value='15'>The Art and Science of Delegation</option>
              <option value='16'>ILEAD Song Lyrics</option>
            </select>
          </div>
        </div>
      </div>

      <div className='card p-8 mt-5 library'>
        <h4> TYCA </h4>
        <div className='row  bd-highlight mb-3'>
          <div className='col-md-6 col-sm-12 col-xs-12'>
            <div className='m-3 bg-dark'>
              <iframe src='https://www.youtube.com/embed/l-qCUvL_ujc' title='video'></iframe>
              <h5> Q/As of Goals of 2021 Speaker: Shyam Taneja </h5>
            </div>
          </div>
          <div className='col-md-6 col-sm-12 col-xs-12'>
            <div className='m-3 bg-dark'>
              <iframe
                width='458'
                height='296'
                src='https://www.youtube.com/embed/u6qU2d3f-B8'
                title='video'
              ></iframe>
              <h5>Leading People to Success: Ms. Sama Taneja</h5>
            </div>
          </div>
        </div>
      </div>

      <div className='card p-8 mt-3 library'>
        <h4> VI Sessions </h4>
        <div className='row flex-wrap bd-highlight mb-3'>
          <div className='col-md-6 col-sm-12 col-xs-12'>
            <div className='m-3 bg-dark'>
              <iframe src='https://www.youtube.com/embed/L3gjet43kSo' title='video'></iframe>

              <h5> VI Session by Mr. Shyam Taneja on 1st January 2021 </h5>
            </div>
          </div>
          <div className='col-md-6 col-sm-12 col-xs-12'>
            <div className='m-3 bg-dark'>
              <iframe src='https://www.youtube.com/embed/ygm3TFwab2o' title='video'></iframe>
              <h5> VI Session by Mr. Shyam Taneja on 15th January 2021 </h5>
            </div>
          </div>
        </div>
      </div>

      <div className='card p-8 mt-3 library'>
        <h4> ILEAD Sampark Presentation </h4>
        <div className='row flex-wrap bd-highlight mb-3'>
          <div className='col-md-6 col-sm-12 col-xs-12 '>
            <div className='m-3 bg-dark'>
              <Image src={toAbsoluteUrl('./media/svg/files/pdf.png')} alt='' />
            </div>
          </div>
        </div>
      </div>

      <div className='card p-8 mt-3 library'>
        <h4> ILEAD Song Lyrics </h4>
        <div className='row  bd-highlight mb-3'>
          <div className='col-md-5 col-sm-12 col-xs-12'>
            <p className='ileade-lyrics'>
              हरेक ज़िन्दगी में लाना है विचार <br />
              हर विचार में लायेंगे हम निखार
              <br />
              सुनो के अब यही है वक़्त की पुकार
              <br />
              आई लीड इसी का नाम है
              <br />
              <br />
              चल पड़े हैं उन्नती की राह में
              <br />
              स्वप्न हैं , गोल हैं , निगाह में
              <br />
              मुश्किलें तो राह में आएँगी
              <br />
              हौसला हमारा आज़माएँगी
              <br />
              <br />
              आएँ जो मुश्किलें उन्हें सभांलेंगे
              <br />
              मिल के साथ साथ बढ़ते जाएँगे
              <br />
              <br />
              बढ़ाओ हाथ गर हो ख़ुद पे ऐतबार
              <br />
              आई लीड इसी का नाम है
              <br />
              <br />
              हरेक ज़िन्दगी में लाना है विचार
              <br />
              हर विचार में लायेंगे निखार
              <br />
              सुनो के अब यही है वक़्त की पुकार
              <br />
              आई लीड इसी का नाम है
              <br />
              <br />
            </p>
          </div>
          <div className='col-md-7 col-sm-12 col-xs-12'>
            <div className='row song-div'>
              <div className='col-3 p-0'>
                <Image src={toAbsoluteUrl('./cover.png')} alt='' />
              </div>
              <div className='col-9 p-0'>
                {/* <ReactAudioPlayer src='my_audio_file.ogg' autoPlay controls /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Library
