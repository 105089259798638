import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Button} from 'react-bootstrap'

const IndexTab = () => {
  return (
    <>
      <ul className='nav nav-tabs index-tab' id='myTab' role='tablist'>
        <li className='nav-item' role='presentation'>
          <button
            className='nav-link active'
            id='home-tab'
            data-bs-toggle='tab'
            data-bs-target='#home'
            type='button'
            role='tab'
            aria-controls='home'
            aria-selected='true'
          >
            Growth
          </button>
        </li>
        <li className='nav-item' role='presentation'>
          <button
            className='nav-link'
            id='profile-tab'
            data-bs-toggle='tab'
            data-bs-target='#profile'
            type='button'
            role='tab'
            aria-controls='profile'
            aria-selected='false'
          >
            Bussiness
          </button>
        </li>
      </ul>

      <div className='tab-content ' id='myTabContent'>
        <div
          className='tab-pane fade show active'
          id='home'
          role='tabpanel'
          aria-labelledby='home-tab'
        >
          <div className='mt-5 pt-8 px-5 pb-5 px-7 index-hdr justify-content-between report-box'>
            <h4 className='fw-bold text-start fs-4 '> Export Average GI Reports </h4>

            <div className='row  mx-0 pt-8  pb-3'>
              <div className='col-4 '>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'> <h3 className='fw-bold fs-4 text-dark text-center  text-uppercase'> Jun 2023 </h3> </div>
              </div>

              <div className='col-4 '>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'> <h3 className='fw-bold fs-4 text-dark text-center text-uppercase'> May 2023 </h3> </div>
              </div>

              <div className='col-4'>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'> <h3 className='fw-bold fs-4 text-dark text-center  text-uppercase'> APR 2023 </h3> </div>
              </div>

              {/* <div className='col-sm-4 col-md-3 col-lg-3 col-xl-2'>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'> <h3 className='fw-bold fs-4 text-dark text-center'> Jun 2023 </h3> </div>
              </div>
              <div className='col-sm-4 col-md-3 col-lg-3 col-xl-2'>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'> <h3 className='fw-bold fs-4 text-dark text-center'> Jun 2023 </h3> </div>
              </div>
              <div className='col-sm-4 col-md-3 col-lg-3 col-xl-2'>
                <div className='d-flex justify-content-between p-5 box-hdr'>
                  <div className='w-dot'></div>
                  <div className='w-dot'></div>
                </div>
                <div className='report-month p-5'> <h3 className='fw-bold fs-4 text-dark text-center'> Jun 2023 </h3> </div>
              </div> */}
            </div>
          </div>

          <div className='row  mx-0 mt-5 pt-8 px-5 pb-3 index-hdr justify-content-between tyca-hdr'>
            <h4 className='text-start text-dark fw-bold mb-6'> View Growth Index </h4>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Month </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option selected> January </option>
                    <option value='1'> February </option>
                    <option value='2'> March </option>
                    <option value='3'> April </option>
                    <option value='4'> May </option>
                    <option value='5'> Jun </option>
                    <option value='6'> July </option>
                    <option value='7'> August </option>
                    <option value='8'> September </option>
                    <option value='9'> Octomber </option>
                    <option value='10'> November </option>
                    <option value='11'> December </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Year </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option value='1'> 2023 </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Leader Name: </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option selected>All Leaders</option>
                    <option value='1'> Chetan Chavda </option>
                    <option value='2'> Darshak Prajapati </option>
                    <option value='3'> Milap Gajjar </option>
                    <option value='4'> Gaurav Shah </option>
                    <option value='5'> Pawan Garg </option>

                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Sort By Points </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option selected>Total Point</option>
                    <option value='1'> Attendence </option>
                    <option value='2'> Visitor Invited </option>
                    <option value='3'> Visitor Attended </option>
                    <option value='4'> Member's Joined </option>
                    <option value='5'> Thank you Given </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-2 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-bold'> Sort Order </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option selected>Descending</option>
                    <option value='1'> Aescending </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='row  mx-0 mt-5 pt-8 px-5 pb-3 index-hdr justify-content-between tyca-hdr mb-view-bg-trans'>
            <div className='growth-listing row mx-0 px-0'>
              <div className='col-lg-12 col-xl-6 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      src={toAbsoluteUrl('./media/avatars/default-user.png')}
                      alt=''
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-7'>
                  <p className='mb-2 fw-bold'> Darshak Prajapati </p>
                  <p className='mb-2 fw-semibold '> Sufalam Technologies Pvt. Ltd. </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'>13</h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>

              <div className='col-lg-12 col-xl-6 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      src={toAbsoluteUrl('./media/avatars/default-user.png')}
                      alt=''
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-7'>
                  <p className='mb-2 fw-bold'> Chetan Chavda </p>
                  <p className='mb-2 fw-semibold '> Web Infinium </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'>0</h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>

              <div className='col-lg-12 col-xl-6 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      src={toAbsoluteUrl('./media/avatars/default-user.png')}
                      alt=''
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-7'>
                  <p className='mb-2 fw-bold'>Gaurav Shah </p>
                  <p className='mb-2 fw-semibold '> SICIWF </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'> 10 </h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>

              <div className='col-lg-12 col-xl-6 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      src={toAbsoluteUrl('./media/avatars/default-user.png')}
                      alt=''
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-7'>
                  <p className='mb-2 fw-bold'> Milap Gajjar </p>
                  <p className='mb-2 fw-semibold '> Sufalam </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'>13</h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className='tab-pane fade' id='profile' role='tabpanel' aria-labelledby='profile-tab'>
          <div className='row  mx-0 mt-5 pt-8 px-5 pb-3 index-hdr justify-content-between tyca-hdr'>
            <h4 className='text-start text-dark fw-bold mb-6'> View Business Index </h4>
            <div className='col-md-3 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-normal'> Session Date Range </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option selected>All</option>
                    <option value='1'> Sep 26, 2019</option>
                    <option value='2'> Oct 4, 2019</option>
                    <option value='3'> Oct 7, 2019</option>
                    <option value='4'> Nov 8, 2019</option>
                    <option value='5'> Dec 27, 2019</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-3 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-normal'> Select Leader Name: </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option selected>All Leaders</option>
                    <option value='1'> Prem </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-3 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-normal'> Sort By Points </h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option selected>All</option>
                    <option value='1'> Attendence </option>
                    <option value='2'> Visitor Invited </option>
                    <option value='3'> Visitor Attended </option>
                    <option value='4'> Member's Joined </option>
                    <option value='5'> Thank you Given </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='col-md-3 mb-3'>
              <div className='filter-div  index-filter mx-0'>
                <div className='fltr-lbl'>
                  {' '}
                  <h4 className='text-dark fw-normal'> Filter By Date</h4>{' '}
                </div>
                <div className=' card rounded-0 py-3'>
                  <select
                    className='form-select px-5 py-3 form-select-sm '
                    aria-label='.form-select-sm example'
                  >
                    <option selected>Descending</option>
                    <option value='1'> Aescending </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='row  mx-0 mt-5 pt-8 px-5 pb-3 index-hdr justify-content-between tyca-hdr mb-view-bg-trans'>
            <div className='growth-listing row mx-0 px-0'>
              <div className='col-lg-12 col-xl-6 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      src={toAbsoluteUrl('./media/avatars/default-user.png')}
                      alt=''
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-7'>
                  <p className='mb-2 fw-bold'> Darshak Prajapati </p>
                  <p className='mb-2 fw-semibold '> Sufalam Technologies Pvt. Ltd. </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'>13</h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>

              <div className='col-lg-12 col-xl-6 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      src={toAbsoluteUrl('./media/avatars/default-user.png')}
                      alt=''
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-7'>
                  <p className='mb-2 fw-bold'> Chetan Chavda </p>
                  <p className='mb-2 fw-semibold '> Web Infinium </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'>0</h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>

              <div className='col-lg-12 col-xl-6 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      src={toAbsoluteUrl('./media/avatars/default-user.png')}
                      alt=''
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-7'>
                  <p className='mb-2 fw-bold'>Gaurav Shah </p>
                  <p className='mb-2 fw-semibold '> SICIWF </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'> 10 </h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>

              <div className='col-lg-12 col-xl-6 d-flex flex-nowrap mb-5'>
                <div className='col-2'>
                  <div className='thumbnail-box'>
                    <img
                      src={toAbsoluteUrl('./media/avatars/default-user.png')}
                      alt=''
                      className='thumbnail-img'
                    />
                  </div>
                </div>
                <div className='col-7'>
                  <p className='mb-2 fw-bold'> Milap Gajjar </p>
                  <p className='mb-2 fw-semibold '> Sufalam </p>
                </div>

                <Button type='button'>
                  <h5 className='fw-semibold fe-6 text-center text-white'>13</h5>
                  <p className='fw-normal fe-7 mb-0 text-center'> Point </p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {IndexTab}
