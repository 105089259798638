import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { TermsConditionsPage } from '../pages/terms-conditions/TermsConditionsPage'
import InvitationHistory from '../modules/invitation/InvitationHistory'
import SearchLeader from '../modules/search-leader/SearchLeader'
import Library from '../modules/Library/Library'
// import LeaderProfile from '../modules/leader-profile/LeaderProfile'
import ICIWFUUPL from '../modules/iciwf-uupl/ICIWFUUPL'
import FeedbackQuery from '../modules/feedback/FeedbackQuery'
import { LeaderProfile } from '../modules/leader-profile/LeaderProfile'
import { SummerySession } from '../modules/summery-session/SummarySession'
import { LeaderDirectory } from '../modules/directory/LeaderDirectory'
import { TycaPresentation } from '../modules/tyca/TycaPresentation'
import { IndexTab } from '../modules/index/Index'


const PrivateRoutes = () => {
  // const InvitationHistory = lazy(() => import('../modules/invitation/InvitationHistory'))
  // const SearchLeader = lazy(() => import('../modules/search-leader/SearchLeader'))
  // const Library = lazy(() => import('../modules/Library/Library'))
  // const ICIWFUUPL = lazy(() => import('../modules/iciwf-uupl/ICIWFUUPL'))
  // const FeedbackQuery = lazy(() => import('../modules/feedback/FeedbackQuery'))

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='invitation-history' element={<InvitationHistory />} />
        <Route path='leaders' element={<SearchLeader />} />
        <Route path='library' element={<Library />} />
        <Route path='leader-profile' element={<LeaderProfile />} />
        <Route path='directory' element={<LeaderDirectory />} />
        <Route path='iciwf-uupl' element={<ICIWFUUPL />} />
        <Route path='terms-conditions' element={<TermsConditionsPage />} />
        <Route path='feedback' element={<FeedbackQuery />} />
        <Route path='tyca' element={<TycaPresentation />} />
        <Route path='index' element={<IndexTab />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='summery-session' element={<SummerySession />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
