import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Button, Form, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'

const PostCommentModal: React.FC = () => {
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    const hideDivAfterTimeout = () => {
      setTimeout(() => {
        setVisible(false)
      }, 5000)
    }

    // Call the function to hide the div after the component mounts
    hideDivAfterTimeout()
  }, [])

  return (
    <div className='modal fade' tabIndex={-1} id='post_comment_view'>
      <div className='modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-dialog-centered modal-md'>
        <div className='modal-content'>
          <div className='modal-header p-3'>
            <h5 className='modal-title text-center w-100'>Comments</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body comment-body'>
            {visible ? (
              <div className='single_comment_view d-flex align-items-start'>
                <div className='empty-comments text-center w-100'>
                  <Image
                    fluid
                    src={toAbsoluteUrl('/media/ilead/empty-comments.png')}
                    className='w-150px mb-4'
                  ></Image>
                  <h4 className='text-gray-900'>No Comments Yet</h4>
                  <span className='text-gray-500'>Be the first to comment</span>
                </div>
              </div>
            ) : (
              <>
                <div className='single_comment_view d-flex align-items-start mb-3'>
                  <div className='symbol symbol-40px me-3'>
                    <Image src={toAbsoluteUrl('/media/avatars/300-3.jpg')}></Image>
                  </div>
                  <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                    <div className='commented_user_details mb-1'>
                      <Link
                        to=''
                        className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'
                      >
                        Nirav Agarvat
                      </Link>
                      <span className='round-separator me-2'></span>
                      <span className='text-gray-600'>a few second ago</span>
                    </div>
                    <div className='commnet_content text-black'>I can I will</div>
                  </div>
                </div>
                <div className='single_comment_view d-flex align-items-start mb-3'>
                  <div className='symbol symbol-40px me-3'>
                    <Image src={toAbsoluteUrl('/media/avatars/300-1.jpg')}></Image>
                  </div>
                  <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                    <div className='commented_user_details mb-1'>
                      <Link
                        to=''
                        className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'
                      >
                        Milap Gajjar
                      </Link>
                      <span className='round-separator me-2'></span>
                      <span className='text-gray-600'>a few second ago</span>
                    </div>
                    <div className='commnet_content text-black'>
                      On the other hand, we denounce with righteous indignation and dislike men who
                      are so beguiled and demoralized by the charms of pleasure of the moment,
                    </div>
                  </div>
                </div>
                <div className='single_comment_view d-flex align-items-start mb-3'>
                  <div className='symbol symbol-40px me-3'>
                    <Image src={toAbsoluteUrl('/media/avatars/300-6.jpg')}></Image>
                  </div>
                  <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                    <div className='commented_user_details mb-1'>
                      <Link
                        to=''
                        className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'
                      >
                        Omdev
                      </Link>
                      <span className='round-separator me-2'></span>
                      <span className='text-gray-600'>a few second ago</span>
                    </div>
                    <div className='commnet_content text-black'>
                      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                    </div>
                  </div>
                </div>
                <div className='single_comment_view d-flex align-items-start mb-3'>
                  <div className='symbol symbol-40px me-3'>
                    <Image src={toAbsoluteUrl('/media/avatars/300-5.jpg')}></Image>
                  </div>
                  <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                    <div className='commented_user_details mb-1'>
                      <Link
                        to=''
                        className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'
                      >
                        Pranav Thakker
                      </Link>
                      <span className='round-separator me-2'></span>
                      <span className='text-gray-600'>a few second ago</span>
                    </div>
                    <div className='commnet_content text-black'>
                      The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for
                      those interested.
                    </div>
                  </div>
                </div>
                <div className='single_comment_view d-flex align-items-start mb-3'>
                  <div className='symbol symbol-40px me-3'>
                    <Image src={toAbsoluteUrl('/media/avatars/300-4.jpg')}></Image>
                  </div>
                  <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                    <div className='commented_user_details mb-1'>
                      <Link
                        to=''
                        className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'
                      >
                        Swati Chauhan
                      </Link>
                      <span className='round-separator me-2'></span>
                      <span className='text-gray-600'>a few second ago</span>
                    </div>
                    <div className='commnet_content text-black'>
                      There are many variations of passages of Lorem Ipsum available, but the
                      majority have suffered alteration in some form, by injected humour,
                    </div>
                  </div>
                </div>
                <div className='single_comment_view d-flex align-items-start mb-3'>
                  <div className='symbol symbol-40px me-3'>
                    <Image src={toAbsoluteUrl('/media/avatars/300-3.jpg')}></Image>
                  </div>
                  <div className='flex-grow-1 d-flex flex-column commnet_box_right'>
                    <div className='commented_user_details mb-1'>
                      <Link
                        to=''
                        className='ilead-text text-hover-primary fs-6 fs-md-5 fw-bold me-2'
                      >
                        Nirav Agarvat
                      </Link>
                      <span className='round-separator me-2'></span>
                      <span className='text-gray-600'>a few second ago</span>
                    </div>
                    <div className='commnet_content text-black'>
                      It is a long established fact that a reader will be distracted by the readable
                      content of a page when looking at its layout.
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='modal-footer py-2'>
            <div className='symbol symbol-40px'>
              <Image src={toAbsoluteUrl('/media/avatars/300-3.jpg')}></Image>
            </div>
            <div className='comment_text_box m-0 flex-1'>
              <Form>
                <Form.Control
                  className='border-0 fw-normal text-black fs-6'
                  type='text'
                  placeholder='Write your comment...'
                  autoFocus
                />
              </Form>
            </div>
            <Button
              variant='link'
              type='submit'
              className='p-0 m-0 symbol symbol-40px symbol-circle'
            >
              <div className='symbol-label bg-primary'>
                <i className='fa-regular fa-paper-plane text-white'></i>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostCommentModal
