import React, {} from 'react'
import {Form, Image, InputGroup} from 'react-bootstrap'

export function SearchLeaderListing({onClick}:any) {
  console.log (onClick)
  const leaderListing = [
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-1.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-2.jpg',
    },
    {
      name: 'Amit Wadhwa',
      companyname: 'Wadhwas House Of Embroidery Pvt. Ltd.',
      photo: '/media/avatars/300-3.jpg',
    },
    {
      name: 'Anil Agrawal',
      companyname: 'Kasba Paridhan Pvt Ltd',
      photo: '/media/avatars/300-4.jpg',
    },
    {
      name: 'Anil Gupta',
      companyname: 'Unnati Unlimited PVT LTD',
      photo: '/media/avatars/300-5.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-6.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-7.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-1.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-2.jpg',
    },
    {
      name: 'Amit Wadhwa',
      companyname: 'Wadhwas House Of Embroidery Pvt. Ltd.',
      photo: '/media/avatars/300-3.jpg',
    },
    {
      name: 'Anil Agrawal',
      companyname: 'Kasba Paridhan Pvt Ltd',
      photo: '/media/avatars/300-4.jpg',
    },
    {
      name: 'Anil Gupta',
      companyname: 'Unnati Unlimited PVT LTD',
      photo: '/media/avatars/300-5.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-6.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-7.jpg',
    },
  ]

  return (
    <div className='leader-listing'>
      <Form.Group className='mb-3'>
        <InputGroup>
          <InputGroup.Text id='SearchLeader' className='bg-white ilead-input-group'>
            <i className='fa-solid fa-magnifying-glass'></i>
          </InputGroup.Text>
          <Form.Control
            placeholder='Type leader name'
            aria-label='LeaderSearch'
            aria-describedby='LeaderSearch'
            className='border-start-0 ps-0 ilead-input-group'
          />
        </InputGroup>
      </Form.Group>
      {leaderListing.map((item: any) => (
        <div
          className='small_leader_box d-flex align-items-center mb-5 cursor-pointer'
          onClick={() => {
            onClick(true)
          }}
        >
          <div className='symbol symbol-45px me-3'>
            <Image src={item.photo}></Image>
          </div>
          <div className='leader_details'>
            <div className='text-black fs-6 fs-md-5 fw-bold mb-0'>{item.name}</div>
            <span className='fs-14 text-gray-600'>{item.companyname}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
