import React from "react";


const SummerySession = () => {
return(
 <>
    <h1 className='inv-hst'> Summery Session </h1>
<div className='row my-8 py-8 px-6 card mx-0'>
 <div className="col-md-4">
    <h3> Chapter </h3>
    <select className="form-select form-select-lg mb-3 mt-3" aria-label=".form-select-lg example">
  <option selected>All</option>
  <option value="1">Eklavya</option>
</select>
 </div>
 <div className="col-md-4">
    <h3> Session </h3>
    <select className="form-select form-select-lg mb-3 mt-3" aria-label=".form-select-lg example">
  <option selected>All</option>
  <option value="1">23-11-2018</option>
  <option value="1">07-12-2018</option>
</select>
 </div>
 <div className="col-md-4">
    <h3> Chapter </h3>
    <select className="form-select form-select-lg mb-3 mt-3" aria-label=".form-select-lg example">
  <option selected>All</option>
  <option value="1">Eklavya</option>
</select>
 </div>
 <div className="col-md-4">
    <h3> Chapter </h3>
    <select className="form-select form-select-lg mb-3 mt-3" aria-label=".form-select-lg example">
  <option selected>All</option>
  <option value="1">Eklavya</option>
</select>
 </div>
</div>

</>

)
}

export {SummerySession}