import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import { FaRegEye } from 'react-icons/fa';
const SearchLeader: React.FC = () => {
  return (
    <>
      <h1 className='inv-hst'>Search Leaders</h1>

      <div className='card mob-vie-card p-8 mt-5'>
        <div className='row m-0'>
          <div className='col-md-6 mb-3'>
            <div className='form-group floating-labels'>
              <select
                className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                aria-label='Default select example'
              >
                <option selected> Select Chapter </option>
                <option value='1'> Trustees </option>
                <option value='2'> Eklavya </option>
              </select>
            </div>
          </div>

          <div className='col-md-6 mb-5'>
            <div className='search-group m-b-30'>
              <input type='search' id='Search' className='form-control searchInput' />
              <button type='button'>
                <i className='fa fa-search'></i>
              </button>
              {/* <span className="highlight"></span> <span className="bar"></span> */}
              <label>Search</label>
            </div>
          </div>
        </div>
        <div className='row ms-0 me-0 mt-5 search-ldr'>
          <div className='col-md-6 col-lg-4 pl-0 bg-white position-relative'>
            <div className='row m-0  '>
              <div className='col-md-3 search-ldr-icon pl-0'>
                <img
                  src={toAbsoluteUrl('./media/avatars/default-user.png')}
                  alt=''
                  className='thumbnail-img'
                />
              </div>
              <div className='col-md-9 px-0 py-5  search-ldr-name'>
                <h3 className='text-dark fw-semibol fs-5 mb-0  text-left'> Anil Gupta </h3>
                <p> Unnati Unlimited Pvt. Ltd. </p>
              
              </div>
              {/* <span className='eye-icon'> <FaRegEye /> </span> */}
            </div>
          </div>
          <div className='col-md-6  col-lg-4 pl-0 bg-white position-relative'>
            <div className='row m-0'>
              <div className='col-md-3 search-ldr-icon pl-0'>
              <img
              src={toAbsoluteUrl('./media/avatars/MS_NEERU_GUPTA.jpg')}
              alt=''
              className='thumbnail-img'
            />
              </div>
              <div className='col-md-9 px-0 py-5  search-ldr-name'>
                <h3 className='text-dark fw-semibol fs-5 mb-0  text-left'> niru Gupta </h3>
                <p> Unnati Unlimited Pvt. Ltd. </p>
              
              </div>
              {/* <span className='eye-icon'> <FaRegEye /> </span> */}
            </div>
          </div>
          <div className='col-md-6 col-lg-4 pl-0 bg-white position-relative'>
            <div className='row m-0'>
              <div className='col-md-3 search-ldr-icon pl-0'>
              <img
              src={toAbsoluteUrl('./media/avatars/MS_SAMA_TANEJA.jpg')}
              alt=''
              className='thumbnail-img'
            />
              </div>
              <div className='col-md-9 px-0 py-5 search-ldr-name'>
                <h3 className='text-dark fw-semibol fs-5 mb-0  text-left'> Sama Taneja </h3>
                <p> Unnati Unlimited Pvt. Ltd. </p>
               
              </div>
              {/* <span className='eye-icon'> <FaRegEye /> </span> */}
            </div>
          </div>

          <div className='col-md-6 col-lg-4 pl-0 bg-white position-relative'>
            <div className='row m-0'>
              <div className='col-md-3 search-ldr-icon pl-0'>
              <img
              src={toAbsoluteUrl('./media/avatars/shyam-taneja.jpg')}
              alt=''
              className='thumbnail-img'
            />
              </div>
              <div className='col-md-9 px-0 py-5 search-ldr-name'>
                <h3 className='text-dark fw-semibol fs-5 mb-0  text-left'> Shyam Taneja </h3>
                <p> Unnati Unlimited Pvt. Ltd. </p>
               
              </div>
              {/* <span className='eye-icon'> <FaRegEye /> </span> */}
            </div>
          </div>
        </div>
      </div>

      <div className='card p-8 mt-5 defalt-text'>
        <p className='text-center fs-4 fw-light mb-0 '>
          Select Chapter or write the name to search leader !
        </p>
      </div>
    </>
  )
}

export default SearchLeader
