import React, {useState} from 'react'
import {Button, Form, Image, InputGroup} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'

export function SayThankYou() {
  const [businessReferralStepOne, setbusinessReferralStepOne] = useState(true)
  const leaderListing = [
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-1.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-2.jpg',
    },
    {
      name: 'Amit Wadhwa',
      companyname: 'Wadhwas House Of Embroidery Pvt. Ltd.',
      photo: '/media/avatars/300-3.jpg',
    },
    {
      name: 'Anil Agrawal',
      companyname: 'Kasba Paridhan Pvt Ltd',
      photo: '/media/avatars/300-4.jpg',
    },
    {
      name: 'Anil Gupta',
      companyname: 'Unnati Unlimited PVT LTD',
      photo: '/media/avatars/300-5.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-6.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-7.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-1.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-2.jpg',
    },
    {
      name: 'Amit Wadhwa',
      companyname: 'Wadhwas House Of Embroidery Pvt. Ltd.',
      photo: '/media/avatars/300-3.jpg',
    },
    {
      name: 'Anil Agrawal',
      companyname: 'Kasba Paridhan Pvt Ltd',
      photo: '/media/avatars/300-4.jpg',
    },
    {
      name: 'Anil Gupta',
      companyname: 'Unnati Unlimited PVT LTD',
      photo: '/media/avatars/300-5.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-6.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-7.jpg',
    },
  ]
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='SayThankYouModal'
        aria-hidden='true'
        aria-labelledby='SayThankYouModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='SayThankYouModalToggleLabel'>
                Say Thank You
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {businessReferralStepOne ? (
                <>
                  <h3 className='text-black fw-bold fs-4 mb-6'>Search Leader</h3>
                  <Form.Group className='mb-7'>
                    <Form.Select aria-label='Default select example' className='ilead-input'>
                      <option>Select Chapter</option>
                      <option value='1'>Trustees</option>
                      <option value='2'>Eklavya</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Search Leader</Form.Label>
                    <InputGroup>
                      <InputGroup.Text id='SearchLeader' className='bg-white ilead-input-group'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder='Type leader name'
                        aria-label='LeaderSearch'
                        aria-describedby='LeaderSearch'
                        className='border-start-0 ps-0 ilead-input-group'
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className='leader_list_scroll_wrapper mt-7'>
                    {/* begin: leader box */}
                    {leaderListing.map((item: any) => (
                      <div
                        className='small_leader_box d-flex align-items-center mb-5 cursor-pointer'
                        onClick={() => {
                          setbusinessReferralStepOne(false)
                        }}
                      >
                        <div className='symbol symbol-45px me-3'>
                          <Image src={item.photo}></Image>
                        </div>
                        <div className='leader_details'>
                          <div className='text-black fs-6 fs-md-5 fw-bold mb-0'>{item.name}</div>
                          <span className='fs-14 text-gray-600'>{item.companyname}</span>
                        </div>
                      </div>
                    ))}
                    {/* end: leader box */}
                  </div>
                </>
              ) : (
                <>
                  <div className='business-ref-ineer'>
                    <div className='border-bottom'>
                      <p className='mb-1 mt-4 text-center fs-6 fw-semibold'>
                        I express my sincere gratitude to Leader
                      </p>
                      <div className='mt-4 gratitude-title ilead-text w-100 p-3 d-block text-center fw-semibold'>
                        Chetan Chavda
                      </div>
                    </div>
                    <Form className='mt-7'>
                      <Form.Group className='mb-4'>
                        <Form.Label htmlFor='name' className='fs-14'>
                          Comment <span style={{color: 'red'}}> *</span>
                        </Form.Label>
                        <TextareaAutosize
                          minRows={3}
                          autoFocus
                          className='custom-text-area form-control fw-normal ilead-input'
                          placeholder=''
                        />
                        <p className='text-gray-500 fs-13 mt-2'>Available Characters 512 </p>
                      </Form.Group>
                      <Form.Group className='mb-5'>
                        <div className='name-wrapper'>
                          <Form.Label className='name-title fs-14'>Name:</Form.Label>
                          <h2 className='fw-semibold mb-3'>Nirav Agarvat</h2>
                        </div>
                        <div className='name-wrapper w-100'>
                          <Form.Label className='name-title'>Date:</Form.Label>
                          <h2 className='fw-semibold mb-3'>10-07-2023</h2>
                        </div>
                      </Form.Group>
                      <Form.Group className='mt-5 d-flex'>
                        <Button
                          variant='secondary'
                          type='button'
                          className='w-md-50 me-3'
                          onClick={() => {
                            setbusinessReferralStepOne(true)
                          }}
                        >
                          Close
                        </Button>
                        <Button variant='primary' type='submit' className='w-md-50 text-uppercase flex-1 white-nowrap'>
                          Say Thank You
                        </Button>
                      </Form.Group>
                    </Form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
