import React from 'react'

const ResetDateChapter = () => {
  return (
    <>
      <div className='modal fade reset-date-psw' tabIndex={-1} id='reset_date_chapter_modal'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title text-white fs-3 fw-bold' id='exampleModalLabel'>
                {' '}
                Select Resend Date And Chapter
              </h4>
              <button
                type='button'
                className='text-white border-0 bg-transparent'
                data-bs-dismiss='modal'
                aria-label='Close'
              > <i className="fa-solid fa-xmark fs-2 text-white" ></i> </button>
            </div>
            <div className='modal-body'>
              <form method='get' id='resendform'>
                <div className='row'>
                  <div className='col-md-12 mb-3'>
                    <div className='form-group floating-labels'>
                      <select
                        className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                        aria-label='Default select example'
                      >
                        <option selected> Select Chapter </option>
                        <option value='1'> Eklavya </option>
                      </select>
                    </div>
                  </div>

                  <div className='col-md-12 mb-5'>
                    <div className='form-group floating-labels'>
                      <select
                        className='form-select border-top-0 border-left-0 border-right-0 border-bottom rounded-0'
                        aria-label='Default select example'
                      >
                        <option selected style={{ background: '6610f2', height: '50px'}}>Select Event Date</option>
                        <option value='1' style={{ background: '6610f2'}}>Select Event Date</option>

                      </select>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                <button className='btn bg-secondary me-4' type='button' data-bs-dismiss='modal' id='closemodel'>
                  Close
                </button>
                <button type='submit' className='btn btn-success' id='modalresend'>
                  Resend
                </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

export {ResetDateChapter}
