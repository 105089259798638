import React, {useState} from 'react'
import {Button, Image} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

export function ThankYouPost({businessrefrral}: any) {
  const [isShowMore, setIsShowMore] = useState(false)

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore)
  }
  return (
    <>
      <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
        <div className='d-flex'>
          {businessrefrral ? (
            <>
              <div className='post-content pe-7 flex-1'>
                <a href='https://www.ileadsampark.org/viewleader?id=52' className='leaderlink'>
                  Lr. Rajendra Patil
                </a>{' '}
                gave Business Referral to{' '}
                <a href='https://www.ileadsampark.org/viewleader?id=52' className='leaderlink'>
                  Lr. Team ILead
                </a>
              </div>
              <div className='thankx_image'>
                <div className='symbol symbol-md-55px symbol-md-55px symbol-circle'>
                  <Image src={toAbsoluteUrl('/media/avatars/300-3.jpg')}></Image>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='post-content pe-7 flex-1'>
                {isShowMore ? (
                  <span>
                    I express sincere gratitude to{' '}
                    <a href='https://www.ileadsampark.org/viewleader?id=52' className='leaderlink'>
                      Lr. Shyam Taneja
                    </a>
                    <br />
                    This ...
                  </span>
                ) : (
                  <span>
                    I express sincere gratitude to{' '}
                    <a href='https://www.ileadsampark.org/viewleader?id=52' className='leaderlink'>
                      Lr. Shyam Taneja
                    </a>
                    <br />
                    This trip was great for my daughter Avi. It was a wonderful trip and now I am
                    very happy to say that will definitely send it next year trip. Thanks for Shyam
                    sir. and Many thanks to the Lr. Preeti and her team who organized this beautiful
                    trip. I can I will. 🙏🙏🙏🙏
                  </span>
                )}
                <br />
                <Button variant='link' onClick={toggleReadMoreLess}>
                  {isShowMore ? 'Show more' : 'Show less'}
                </Button>
              </div>
              <div className='thankx_image'>
                <div className='symbol symbol-md-55px symbol-md-55px symbol-circle'>
                  <Image src={toAbsoluteUrl('/media/avatars/300-3.jpg')}></Image>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* end:post */}
    </>
  )
}
