import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const LeaderProfile = () => {
  return (
    <>
      <h1 className='inv-hst'> Leader View </h1>

      <div className='row my-8 leader-view'>
        <div className='col-md-4 align-self-stretch  p-5'>
          <div className='m-1 leder-info card p-8'>
            <div className="row justify-content-between">
                <div className='col-4 pt-3 cursor-pointer'> <i className="fa-solid fa-share-from-square fs-4"></i> </div> 
                <div className='col-4 text-end'><button type="button" className="btn  btn-primary rounded-lg py-2 ps-3 pe-2 " > Edit </button>
                </div>
                </div>
            <div className='col-md-9 text-center col-sm-9 mx-auto'>
              <img src={toAbsoluteUrl('./media/avatars/default-user.png')} alt='' className='' />
            </div>
            <h4 className='fw-bold uppercase fs-3 text-center mt-5'> Anil Gupta </h4>
            <p className='text-center mb-0'>
              {' '}
              <i className='fa-solid fa-phone'></i> +919909001479{' '}
            </p>
            <p className='text-center'>
              {' '}
              <i className='fa fa-envelope'></i>
              <Link to='#' className='ml-3 text-dark'>
                {' '}
                anil@unnatiunlimited.com
              </Link>
            </p>
            <p className='text-center d-sm-block d-md-none'>
              {' '}
              <i className='fa-solid fa-globe'></i>
              <Link to='#' className='ml-3 text-dark'>
                {' '}
              </Link>
            </p>
          </div>
        </div>

        <div className='col-md-8  p-5'>
          <div className='m-1 bussiness-info card p-8'>
            <h3 className='fs-4 text-dark text-left fw-semibold  d-sm-block d-md-none'>
              {' '}
              Business Info{' '}
            </h3>

            <div className='row mb-md-5'>
              <div className='col-md-6 col-sm-6'>
                Company Name <br />{' '}
                <span className='theme-text text-capitalize'>
                  <strong>Unnati Unlimited PVT LTD</strong>
                </span>
              </div>
              <div className='col-md-6 col-sm-6'>
                Business Category <br />{' '}
                <span className='theme-text text-capitalize'>
                  <strong>Not Updated</strong>
                </span>
              </div>
            </div>
            <div className='row p-t-20'>
              <div className='col-md-6 col-sm-6'>
                Company Address <br /> <span className='text-black'>Not Updated</span>
              </div>
              <div className='col-md-6 col-sm-6'>
                Correspondence Address <br /> <span className='text-black '>Not Updated</span>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-md-12'>
                <h4 className='mb-0'>Vision</h4>
                <p className='themes'>Not Updated</p>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-md-12'>
                <h4 className='mb-0'>Mission</h4>
                <p className='themes'>Not Updated</p>
              </div>
            </div>
            <hr />
            <div className='row  buss-card'>
              <div className='col-md-12 '>
                <h4 className='fs-4 text-dark text-left fw-semibold border-bottom-1 border-dark'> Business Info </h4>
                </div>
                <div className=' col-md-6'> 
                <img src={toAbsoluteUrl('./media/icons/duotune/business-card.png')} alt='' className='' />
                 </div>
              </div>
              <hr />
              <div className='row mt-8'>
              <div className='col-md-12 buss-card'>
                <h4 className='fs-4 text-dark text-left fw-semibold border-bottom-1 border-dark'> Bussiness Profile </h4>
              </div>
              <div className='col-6 buss-pro'> 
              <img src={toAbsoluteUrl('./media/icons/duotune/pdf.png')} alt='' className='' />
              </div>
              <div className='col-6 upld-icon text-md-end'> <i className="fa-solid fa-cloud-arrow-up fs-1"></i> </div>
            </div>
            </div>         
          </div>
        </div>
   

      <div className='row ms-0 me-0 leader-perform'>
        <div className='col-md-12 '>
         <div className='card px-8 py-12'>
          <h5> Leader Perform </h5>
          <div className='row mx-0 mt-8'>
            <div className='col-lg-3 ps-0 col-md-6 col-sm-12 '>
              <div className='p-0 thankyou py-5'>
                <h4 className='fs-5 text-white fw-normal text-center mb-0'>
                  Thank You Card Received
                </h4>
              </div>
              <div className='leader_box'>
                <button
                  type="button"
                  className='thanks_count'
                  data-bs-toggle="modal" data-bs-target="#exampleModal"
                >
                  0
                </button>
              </div>
            </div>
            <div className='col-lg-3 ps-0 col-md-6 col-sm-12 '>
              <div className='p-0 thankyou py-5'>
                <h4 className='fs-5 text-white fw-normal text-center mb-0'>
                Thank You Card Given
                </h4>
              </div>
              <div className='leader_box'>
              <button
                  type="button"
                  className='thanks_count'
                  data-bs-toggle="modal" data-bs-target="#exampleModal"
                >
                  0
                </button>
              </div>
            </div>
            <div className='col-lg-3 ps-0 col-md-6  col-sm-12 '>
              <div className='p-0 thankyou py-5'>
                <h4 className='fs-5 text-white fw-normal text-center mb-0'>
                Visitor(s) Invited
                </h4>
              </div>
              <div className='leader_box'>
              <button
                  type="button"
                  className='thanks_count'
                  data-bs-toggle="modal" data-bs-target="#exampleModal"
                >
                  0
                </button>
              </div>
            </div>
            <div className='col-lg-3 ps-0 col-md-6 col-sm-12 '>
              <div className='p-0 thankyou py-5'>
                <h4 className='fs-5 text-white fw-normal text-center mb-0'>
                Member(s) Joined
                </h4>
              </div>
              <div className='leader_box'>
              <button
                  type="button"
                  className='thanks_count'
                  data-bs-toggle="modal" data-bs-target="#exampleModal"
                >
                  0
                </button>
              </div>
            </div>
          </div>
          </div>

        </div>
      </div>

      <div className='row ms-0 me-0 bussiness-info mt-5'>
        <div className='col-md-12'>
        <div className='card px-8 py-12'>
          <div className='white-box'>
            <div className='row'>
              <div className='col-md-6'>
                <h3 className='theme-text'>Organization</h3>
                <p className='themes'>Not Updated </p>
              </div>
              <div className='col-md-6'>
                <h3 className='theme-text'>Products Of Company</h3>
                <p className='themes'>Not Updated </p>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-md-6'>
                <h3 className='theme-text'>Growth Story Of Business</h3>
                <p className='themes'>Not Updated </p>
              </div>

              <div className='col-md-6'>
                <h3 className='theme-text'>Achievements</h3>
                <p className='themes'>Not Updated </p>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-md-6'>
                <h3 className='theme-text'>Business Challanges</h3>
                <p className='themes'>Not Updated </p>
              </div>

              <div className='col-md-6'>
                <h3 className='theme-text'>Goal For Next 3 To 5 Years</h3>
                <p className='themes'>Not Updated </p>
              </div>
            </div>

            <hr />
            <div className='row'>
              <div className='col-md-6'>
                <h3 className='theme-text'>Membership Enrollment Date</h3>
                <p className='themes'> <span> <i className="fa fa-calendar-days fs-3"></i> </span> - </p>
              </div>

              <div className='col-md-6'>
                <h3 className='theme-text'>Membership Renewal Date</h3>
                <p className='themes'> <span> <i className="fa fa-calendar-days fs-3"></i> </span> - </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className='modal-dialog modal-lg leader-counter-modal ' role='document'>
          <div className='modal-content'>
            <div className='modal-header text-left border-bottom-0 border-radius-0'>
              <h4 className='text-white'>Received Thank You Cards</h4>
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true' className='text-white fs-2'>×</span>
              </button>
            </div>
            <div className='modal-body border-radius-0'>
                 <div className="row mb-4">
                    <div className="col-sm-6 mb-4">
                        <div className="dataTables_length" id="thankyou_receive_tbl_length">
                            <label>Show <select name="thankyou_receive_tbl_length" aria-controls="thankyou_receive_tbl" className="">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option></select> entries</label>
                                </div>
                                </div>
                                <div className="col-sm-6 text-md-end text-sm-start mb-4" >
                                    <div id="thankyou_receive_tbl_filter" className="dataTables_filter">
                                        <label> Search: <input name="myInput" /> </label>
                                        </div>
                                        </div>
                                        </div>
              <div className='card invitation-history'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th scope='col'>Id</th>
                      <th scope='col'>Sender Name</th>
                      <th scope='col'> Reason </th>
                      <th scope='col'> Date </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label='Id'> 123 </td>
                      <td data-label='Sender Name'> Aiyub Panara </td>
                      <td data-label='Reason'> Lorem Ipsum </td>
                      <td data-label='Date'> 15th july 2023 </td>
                    </tr>
                  </tbody>
                </table>
              </div>
               
               <div className='row mt-8'> <nav aria-label="Page navigation example">
  <ul className="pagination justify-content-end">
    <li className="page-item disabled">
      <a className="page-link" href="#" tabIndex={-1}>Previous</a>
    </li>
    <li className="page-item"><a className="page-link" href="#">1</a></li>
    <li className="page-item"><a className="page-link" href="#">2</a></li>
    <li className="page-item"><a className="page-link" href="#">3</a></li>
    <li className="page-item">
      <a className="page-link" href="#">Next</a>
    </li>
  </ul>
</nav></div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {LeaderProfile}
