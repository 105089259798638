import React, {useEffect, useRef} from 'react'
import {Image} from 'react-bootstrap'
import Zoom from 'react-medium-image-zoom'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useReward} from 'react-rewards'
const useInterval = (callback: () => void) => {
  const callbackRef = useRef(() => {})

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const timerId = setInterval(() => callbackRef.current(), 2000)
    return () => clearInterval(timerId)
  }, [])
}

const AnimationStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
}

export function BirthDayPost() {
  const {reward: rewardLeft, isAnimating: isAnimatingLeft} = useReward('rewardLeft', 'confetti', {
    angle: 45,
    position: 'absolute',
  })
  const {reward: rewardRight, isAnimating: isAnimatingRight} = useReward(
    'rewardRight',
    'confetti',
    {
      angle: 135,
      position: 'absolute',
    }
  )

  useInterval(() => {
    if (!isAnimatingRight || !isAnimatingLeft) {
      rewardLeft()
      rewardRight()
    }
  })
  return (
    <>
      {/* begin:Annoucement post */}
      <div className='card-body pt-0 pb-0 ps-0 pe-0 position-relative overflow-hidden'>
        <div className='media'>
          <div style={AnimationStyle} className='position-absolute w-100 h-100'>
            <div className='popper'>
              <span id='rewardLeft' />
            </div>
            <div className='popper'>
              <span id='rewardRight' />
            </div>
          </div>
          <Zoom>
          <Image
            src={toAbsoluteUrl('media/ilead/gibi-post.jpg')}
            className='d-block w-100'
          ></Image>
          </Zoom>
        </div>
      </div>
      {/* end:post */}
    </>
  )
}
