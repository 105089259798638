import React from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'

export function DeletePostModal() {
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='delete_post_modal'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Are you sure you want to delete this post?</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body fs-5'>
              <p>This will delete this post permanently. You cannot undo this action.</p>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-primary'>
                Yes
              </button>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
