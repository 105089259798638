import React from 'react'
import {Button, Form} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'

export function AskForReferral() {
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='AskforReferrralModal'
        aria-hidden='true'
        aria-labelledby='AskforReferrralModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='AskforReferrralModalToggleLabel'>
                Looking for Help / Referral
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              <Form className='mb-7'>
                <h3 className='text-black fw-bold fs-4 mb-6'>Select Chapter to Post</h3>
                <Form.Group className='mb-3'>
                  <Form.Check
                    label='Beta'
                    name='chapter'
                    type='radio'
                    className='mb-5 me-7'
                    checked
                    id='beta'
                    inline
                  />
                  <Form.Check
                    label='All Chapter'
                    name='chapter'
                    type='radio'
                    className='mb-5'
                    id='allchapter'
                    inline
                  />
                </Form.Group>
                <Form.Group className='mb-3'>
                  <TextareaAutosize
                    minRows={3}
                    autoFocus
                    className='custom-text-area form-control fw-normal ilead-input'
                    placeholder='Seek help in improving quality of your life, personal challenges or any noble task'
                  />
                  <p className='text-gray-500 fs-13'>Available Characters 2000 </p>
                </Form.Group>
                <Form.Group className='mb-3 mt-7'>
                  <h5 className='mt-7 mb-7'>Add Attachment</h5>
                  <Button variant='primary' className='me-3 px-4 py-3'>
                    <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                  </Button>
                  <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                    <Form.Control
                      type='file'
                      className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                    ></Form.Control>
                    <i className='bi bi-image-fill pe-0' style={{fontSize: '20px'}}></i>
                  </div>
                  <Button variant='primary' className='px-4 py-3'>
                    <i className='bi bi-folder-fill pe-0' style={{fontSize: '20px'}}></i>
                  </Button>
                </Form.Group>
                <Button variant='primary' type='submit' className='w-100 mt-7'>
                  Submit
                </Button>
              </Form>
              <div className='note-description mt-7 fs-6 text-gray-700'>
                *Note : Only image(jpg/png) or file(PDF/Word/Excel/PPT) is allowed to upload !
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* begin: Ask for Help Modal */}
    </>
  )
  document.getElementById('element')
}
