import React, {useState} from 'react'
import {Button} from 'react-bootstrap'
import {DeletePostModal} from './DeletePostModal'
import PostCommentModal from '../post-comments/PostCommentModal'
import Heart from 'react-animated-heart'

export function PostFooter({alloption}: any) {
  const [iCanIwillComment, setICanIwillComment] = useState(true)
  const [isClick, setClick] = useState(false)

  return (
    <>
      <div className='comment-count d-flex flex-nowrap justify-content-between px-2 px-4 pb-3 text-gray-700 fs-13'>
        <div className='like_counter'>
          {iCanIwillComment ? (
            <div className='icaniwill-comment-view'>
              I Can I Will by Rohit Prajapati and 6 others
            </div>
          ) : (
            <div className='icaniwill-comment-view'>I Can I Will by you and 7 others</div>
          )}
        </div>
        <div className='comment_counter'>1 Comments</div>
      </div>
      <div className='card-footer p-0'>
        <ul className='nav py-0 flex-nowrap'>
          <li
            className={
              !alloption
                ? 'nav-item w-50 justify-content-center d-flex action position-relative'
                : 'nav-item justify-content-center d-flex flex-1 action position-relative'
            }
          >
            <div
              onClick={() => {
                setICanIwillComment(false)
                setClick(!isClick)
              }}
              className='nav-link btn-color-gray-800 px-2 fw-normal w-100 position-relative fs-7 fs-md-6 cursor-pointer'
            >
              <Heart isClick={isClick} onClick={() => {}}></Heart>I Can I Will
            </div>
          </li>
          <li
            className={
              !alloption
                ? 'nav-item w-50 justify-content-center d-flex'
                : 'nav-item justify-content-center d-flex flex-1 action position-relative'
            }
          >
            <Button
              variant='link'
              className='nav-link btn btn-sm btn-color-gray-800 px-2 fw-normal w-100 position-relative fs-7 fs-md-6'
              data-bs-toggle='modal'
              data-bs-target='#post_comment_view'
            >
              <i className='bi bi-chat'></i> Comment
            </Button>
          </li>
          {alloption ? (
            <>
              <li className='nav-item justify-content-start d-flex flex-1 position-relative'>
                <Button
                  variant='link'
                  className='nav-link btn btn-sm btn-color-gray-800 px-2 fw-normal w-100 share-button position-relative fs-7 fs-md-6 action position-relative'
                >
                  <i className='bi bi-share-fill'></i>
                </Button>
                <Button
                  variant='link'
                  className='nav-link btn btn-sm btn-color-gray-800 px-2 me-0 fw-normal w-50px align-items-center position-absolute end-0'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  style={{minHeight: '44px'}}
                >
                  <i className='bi bi-three-dots-vertical me-0 pe-0'></i>
                </Button>
                <div className='menu menu-sub menu-sub-dropdown w-150px px-5' data-kt-menu='true'>
                  <Button variant='link' className='text-start pt-3 pb-2'>
                    Hide
                  </Button>
                  <Button
                    variant='link'
                    className='text-start pt-2 pb-3'
                    data-bs-toggle='modal'
                    data-bs-target='#delete_post_modal'
                  >
                    Delete
                  </Button>
                </div>
              </li>
            </>
          ) : (
            <></>
          )}
          <DeletePostModal></DeletePostModal>
          <PostCommentModal></PostCommentModal>
        </ul>
      </div>
    </>
  )
}
