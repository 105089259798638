import React from 'react'
// import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Button } from 'react-bootstrap'

const FeedbackQuery: React.FC = () => {
  return (
    <>
      <h1 className='inv-hst'>Feedback Query</h1>
      <div className='my-8 py-8 px-6 col-md-7 mx-auto card feedback'>
        <h4 className='text-dark fs-6 text-start fw-medium ps-0 pb-5 border-bottom line-h-24 border-secondary'>
          {' '}
          Please Submit any queries or feedback regarding ILEAD Sampark{' '}
        </h4>
        <form action='' method=''className=''>
        <div className='row'>
          <div className='col-md-12 md-ps-0'>
            <textarea
              className='border-top-0 border-left-0 border-right-0 w-100 border-bottom border-secondary py-3 px-0 fs-6 fw-normal text-start'
              placeholder='Write here...' rows={4}
            ></textarea>
            <p className='text-dark fw-normal fs-7 text-start mt-4 ps-0'>
              {' '}
              Available Characters 1000{' '}
            </p>
          </div>

          <div className='col-md-12 md-ps-0pt-3'>
            <h4 className='text-dark fs-6 text-start fw-medium ps-0 pb-5 border-bottom border-secondary'>
              {' '}
              Attachment{' '}
            </h4>
            <div className='row mx-0 fdbk-icon mt-10'>
            <div className=' col-4 text-center'> 
            <Button variant='primary' className='me-3 px-4 py-3'>
                    <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                  </Button>
            </div>
            <div className=' col-4 text-center'> 
            <Button variant='primary' className='px-4 py-3'>
            <i className='bi bi-image-fill pe-0' style={{fontSize: '20px'}}></i>
            </Button>
            </div>
            <div className=' col-4 text-center'> 
            <Button variant='primary' className='px-4 py-3'>
                    <i className='bi bi-folder-fill pe-0' style={{fontSize: '20px'}}></i>
                  </Button>
            {/* <img src={toAbsoluteUrl('./media/icons/duotune/copy.svg')} alt='' className=' document ' /> */}
            </div>
          </div>
          </div>
        </div>
<div className='row justify-content-center mt-12'>
      <div className='col-md-12 md-ps-0 text-center pt-3'>
                 <Button type="submit" className='py-3 px-5 fs-5 w-100 fw-normal text-white text-center'> Submit </Button>
          </div>

</div>

        </form>
      </div>
    </>
  )
}

export default FeedbackQuery
