import React from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {FaRegEye} from 'react-icons/fa'

const LeaderDirectory = () => {
  return (
    <>
      <h1 className='inv-hst'> Leader Directory </h1>

      <div className='card p-8 grp-facltr  mt-8 mb-5'>
      <h3 className='text-center fw-bold mb-8'> Chapter Leadership </h3>

<div className='row mt-5'>
<div className='col-lg-12 col-xl-6 mb-5'>
  <div className='d-flex flex-nowrap'>
    <div className='col-2'>
      <div className='thumbnail-box'>
        <img
          src={toAbsoluteUrl('./media/avatars/default-user.png')}
          alt=''
          className='thumbnail-img'
        />
      </div>
    </div>
    <div className='col-7 col-xs-12'>
      <p className='mb-2 fw-bold'> Darshak Prajapati </p>
      <p className='mb-2 fw-semibold '> Beta 1</p>
      <p className='mb-1'>
        <i className='fa fa-phone'></i>{' '}
        <span style={{color: '#007bff', fontWeight: 'bold'}}> +91 98249000000 </span>
      </p>
      <p className='mb-1'>
        <i className='fa fa-envelope'></i>
        <Link to='#' className='ml-3 text-dark'>
          {' '}
          dasrshak.prajapati@sufalamtech.com
        </Link>
      </p>
    </div>
  </div>
  </div>
  <div className='col-lg-12 col-xl-6 mb-5'>
  <div className='d-flex flex-nowrap'>
    <div className='col-2'>
      <div className='thumbnail-box'>
        <img
          src={toAbsoluteUrl('./media/avatars/default-user.png')}
          alt=''
          className='thumbnail-img'
        />
      </div>
    </div>
    <div className='col-7'>
      <p className='mb-2 fw-bold'> Darshak Prajapati </p>
      <p className='mb-2 fw-semibold '> Beta 1</p>
      <p className='mb-1'>
        <i className='fa fa-phone'></i>{' '}
        <span style={{color: '#007bff', fontWeight: 'bold'}}> +91 98249000000 </span>
      </p>
      <p className='mb-1'>
        <i className='fa fa-envelope'></i>
        <Link to='#' className='ml-3 text-dark'>
          {' '}
          dasrshak.prajapati@sufalamtech.com
        </Link>
      </p>
    </div>
  </div>
  </div>

</div>

      </div>

      <div className='card p-8 grp-facltr  mt-8 mb-5'>
      <h3 className='text-center fw-bold mb-8'> Group Facilitator </h3>

        <div className='row mt-5'>
        <div className='col-lg-12 col-xl-6 mb-5'>
          <div className='d-flex flex-nowrap'>
            <div className='col-2'>
              <div className='thumbnail-box'>
                <img
                  src={toAbsoluteUrl('./media/avatars/default-user.png')}
                  alt=''
                  className='thumbnail-img'
                />
              </div>
            </div>
            <div className='col-7'>
              <p className='mb-2 fw-bold'> Darshak Prajapati </p>
              <p className='mb-2 fw-semibold '> Beta 1</p>
              <p className='mb-1'>
                <i className='fa fa-phone'></i>{' '}
                <span style={{color: '#007bff', fontWeight: 'bold'}}> +91 98249000000 </span>
              </p>
              <p className='mb-1'>
                <i className='fa fa-envelope'></i>
                <Link to='#' className='ml-3 text-dark'>
                  {' '}
                  dasrshak.prajapati@sufalamtech.com
                </Link>
              </p>
            </div>
          </div>
          </div>
          <div className='col-lg-12 col-xl-6 mb-5'>
          <div className='d-flex flex-nowrap'>
            <div className='col-2'>
              <div className='thumbnail-box'>
                <img
                  src={toAbsoluteUrl('./media/avatars/default-user.png')}
                  alt=''
                  className='thumbnail-img'
                />
              </div>
            </div>
            <div className='col-7'>
              <p className='mb-2 fw-bold'> Darshak Prajapati </p>
              <p className='mb-2 fw-semibold '> Beta 1</p>
              <p className='mb-1'>
                <i className='fa fa-phone'></i>{' '}
                <span style={{color: '#007bff', fontWeight: 'bold'}}> +91 98249000000 </span>
              </p>
              <p className='mb-1'>
                <i className='fa fa-envelope'></i>
                <Link to='#' className='ml-3 text-dark'>
                  {' '}
                  dasrshak.prajapati@sufalamtech.com
                </Link>
              </p>
            </div>
          </div>
          </div>

        </div>

      </div>

      <div className='row justify-content-end'>
        <div className='col-md-6 d-flex flex-column flex-end library-filter  px-4'>
          <div className='filter-div dir-filter'>
            <h4 className='text-dark fw-normal'> Filter By </h4>
            <select className='form-select form-select-sm ' aria-label='.form-select-sm example'>
              <option selected>All</option>
              <option value='1'>ILEAD Surkhiya</option>
              <option value='2'>ILEAD Sampark Presentation</option>
              <option value='3'>Find Out Coach!</option>
              <option value='4'>CHATBOX WISDOM of 08-01</option>
              <option value='5'>Build India</option>
              <option value='6'>Brochure</option>
              <option value='7'>TYCA</option>
              <option value='8'>VI Session</option>
              <option value='9'>VA Session</option>
              <option value='10'>Nagative Changes by Paresh Kariya</option>
              <option value='11'>Converting Crisis into Opportunity</option>
              <option value='12'>The Art of Delegation</option>
              <option value='13'>Sales Engine - Part || </option>
              <option value='14'>Dialogue with Coach Shyam Taneja</option>
              <option value='15'>The Art and Science of Delegation</option>
              <option value='16'>ILEAD Song Lyrics</option>
            </select>
          </div>
        </div>
      </div>
      <div className='card mob-vie-card sm-ms-3 p-8 mt-5 direciry-all'>
        <div className='row  mx-0 md-mt-20 all-user'>
          <div className='col-md-4 col-lg-6 '>
            <div className='thumbnail-box mx-auto text-center'>
              <img
                src={toAbsoluteUrl('./media/avatars/default-user.png')}
                alt=''
                className='thumbnail-img'
              />
            </div>
            <p className='mt-3 fw-bold'> Chetan Chavda </p>
            <p className=' fw-semibold '> Web Infinium </p>
            <p>
              {' '}
              <i className='fa fa-phone'></i> +91 98249000000{' '}
            </p>
            <p>
              {' '}
              <i className='fa fa-envelope'></i>
              <Link to='#' className='ml-3 text-dark'>
                {' '}
                Chetan@webinfinium.com
              </Link>
            </p>
            <p>
              {' '}
              <i className='fa fa-globe'></i>{' '}
            </p>
            <p className='fw-semibold'> Beta 1 </p>
            <span className='eye-icon'>
              {' '}
              <FaRegEye />{' '}
            </span>
          </div>
          <div className='col-md-4 col-lg-6 defaultuser'>
            <div className='thumbnail-box mx-auto text-center'>
              <img
                src={toAbsoluteUrl('./media/avatars/default-user.png')}
                alt=''
                className='thumbnail-img'
              />
            </div>
            <p className='mt-3 fw-bold'> Darshak Prajapati </p>
            <p className=' fw-semibold uppercase'> Sufalam Technology Pvt. Ltd. </p>
            <p>
              {' '}
              <i className='fa fa-phone'></i> +91 9537608322{' '}
            </p>
            <p>
              {' '}
              <i className='fa fa-envelope'></i>
              <Link to='#' className='ml-3 text-dark'>
                {' '}
                dasrshak.prajapati@sufalamtech.com
              </Link>
            </p>
            <p>
              {' '}
              <i className='fa fa-globe'></i>
              <Link to='#' className='ml-3 text-dark'>
                {' '}
                https://sufalam.atlassian.net/{' '}
              </Link>
            </p>
            <p className=' fw-semibold '>
              {' '}
              Beta 1 <i className='fa fa-gem'></i>{' '}
            </p>
            <span className='eye-icon'>
              {' '}
              <FaRegEye />{' '}
            </span>
          </div>
          <div className='col-md-4 col-lg-6 '>
            <div className='thumbnail-box mx-auto text-center'>
              <img
                src={toAbsoluteUrl('./media/avatars/default-user.png')}
                alt=''
                className='thumbnail-img'
              />
            </div>
            <p className='mt-3 fw-bold'> Gaurav Shah </p>
            <p className=' fw-semibold uppercase'> ICIWF </p>
            <p>
              {' '}
              <i className='fa fa-phone'></i> +91 8200310991{' '}
            </p>
            <p>
              {' '}
              <i className='fa fa-envelope'></i>
              <Link to='#' className='ml-3 text-dark'>
                {' '}
                mustfa.shaikh@sufalamtech.com
              </Link>
            </p>
            <p>
              {' '}
              <i className='fa fa-globe'></i>{' '}
            </p>
            <p className='fw-semibold'> Beta 1 </p>
            <span className='eye-icon'>
              {' '}
              <FaRegEye />{' '}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export {LeaderDirectory}
