import React, {useState} from 'react'
import {Button, Form, InputGroup} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import {SearchLeaderListing} from './SearchLeaderListing'

export function BusinessConnect() {
  const [searchLeaderListing, setsearchLeaderListing] = useState(true)
  return (
    <div
      className='modal fade ilead-default-modal'
      id='BusinessConnectModal'
      aria-hidden='true'
      aria-labelledby='BusinessConnectModalLabel'
    >
      <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
        <div className='modal-content'>
          <div className='modal-header py-5'>
            <h3 className='modal-title fs-3 fw-bold' id='BusinessConnectModalLabelToggleLabel'>
              Business Connect
            </h3>
            <button
              type='button'
              className='btn-close text-white'
              data-bs-target='#write_post_modal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
            ></button>
          </div>
          <div className='modal-body pt-7 write-post-wrapper'>
            {searchLeaderListing ? (
              <>
                <Form className='mb-7'>
                  <h3 className='text-black fw-bold fs-4 mb-6'>Business Connect For Leader</h3>
                  <Form.Group className='mb-4'>
                    <Form.Label
                      className='fs-14 border-bottom w-100 py-2 text-gray-700'
                      onClick={() => {
                        setsearchLeaderListing(false)
                      }}
                    >
                      Select leader name
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label className='w-100 py-2 text-gray-900 fw-bold'>
                      Whom you met?
                    </Form.Label>
                    <InputGroup className='mb-2'>
                      <Form.Control
                        placeholder='Name'
                        aria-label='name'
                        id='name'
                        className='border-right-0 ilead-input'
                      />
                      <InputGroup.Text id='name' className='bg-white ilead-input-group'>
                        <i className='fa-solid fa-address-book' style={{fontSize: '18px'}}></i>
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Control
                      type='text'
                      id='companyName'
                      className='ilead-input mb-2'
                      placeholder='Company Name'
                    />
                    <Form.Control
                      type='text'
                      id='cellNumber'
                      className='ilead-input'
                      placeholder='CellNumber'
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label className='w-100 py-2 text-gray-900 fw-bold'>
                      Write agenda of the meeting
                    </Form.Label>
                    <TextareaAutosize
                      minRows={5}
                      autoFocus
                      className='custom-text-area form-control fw-normal ilead-input'
                      placeholder='Enter agenda of the meeting'
                    />
                  </Form.Group>
                  <Form.Group className='mb-3 mt-7'>
                    <h5 className='mt-7 mb-7'>Upload photo of the meeting</h5>
                    <Button variant='primary' className='me-3 px-4 py-3'>
                      <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                    </Button>
                    <Button variant='primary' className='me-3 px-4 py-3'>
                      <i className='bi bi-image-fill pe-0' style={{fontSize: '20px'}}></i>
                    </Button>
                  </Form.Group>
                  <Form.Group className='mt-5 d-flex'>
                    <Button
                      variant='secondary'
                      type='button'
                      className='w-50 me-3'
                      data-bs-target='#write_post_modal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      Cancel
                    </Button>
                    <Button variant='primary' type='submit' className='w-50'>
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
              </>
            ) : (
              <SearchLeaderListing onClick={setsearchLeaderListing}></SearchLeaderListing>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
