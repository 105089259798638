import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {FaRegEye} from 'react-icons/fa'
import {Button} from 'react-bootstrap'

const TycaPresentation = () => {
  return (
    <>
      <h1 className='inv-hst sm-ms-2'> TYCA Presentation </h1>
      <div className='card mob-vie-card tyca-hdr'>
      <div className='row  mx-0 mt-5 justify-content-between tyca-hdr'>
        <div className='col-md-4 mb-3'>
          <div className='card rounded-0 py-2'>
            <div className='form-group floating-labels'>
              <select
                className='form-select border-0 rounded-0'
                aria-label='Default select example'
              >
                <option selected> Upcomming Presentation </option>
                <option value='1'> All Presentation </option>
                <option value='2'> Eklavya </option>
              </select>
            </div>
          </div>
        </div>

        <div className='col-md-6 mb-3'>
          <div className='filter-div row tyca-filter mx-0'>
            <div className='col-md-4'>
              {' '}
              <h4 className='text-dark fw-normal'> Filter By Date</h4>{' '}
            </div>
            <div className='col-md-8 card rounded-0 py-3'>
              <select
                className='form-select border-0 form-select-sm pt-1 pb-0'
                aria-label='.form-select-sm example'
              >
                <option selected>All</option>
                <option value='1'> Sep 26, 2019</option>
                <option value='2'> Oct 4, 2019</option>
                <option value='3'> Oct 7, 2019</option>
                <option value='4'> Nov 8, 2019</option>
                <option value='5'> Dec 27, 2019</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className='row mx-0 no-presentation'>
        <div className='col-sm-12   '>
          <div className='card my-6 rounded-3 px-6 py-8'>
            <h3 className='fs-4'> There are no upcomming TYCA presentation ! </h3>
          </div>
        </div>
      </div>

      <div className='card mob-vie-card sm-ms-3 p-5 mt-5 direciry-all tyca-card-view'>
        <div className='row  mx-0 p-3 mb-3 all-user'>
          <div className='col-md-6 col-lg-6 col-xl-4 p-8 px-3 mb-5  '>
            <h3 className='mb-7 fs-4'> Presentation On Leadership </h3>
            <div className='thumbnail-box mx-auto text-center'>
              <img
                src={toAbsoluteUrl('./media/avatars/default-user.png')}
                alt=''
                className='thumbnail-img'
              />
            </div>
            <p className='mt-3 fw-bold'> Darshak Prajapati </p>
            <p className=' fw-semibold '> Sufalam Technology Pvt Ltd </p>
            <p className=' fw-semibold '> Information Technology's </p>
            <p>
              {' '}
              <i className='fa fa-globe'></i> Https://Sfalam.Atlassian.Net/
            </p>

            <Button className='eye-icon' type='button'>
              <img
                src={toAbsoluteUrl('./media/svg/files/pdf-white.svg')}
                alt=''
                style={{width: '20px'}}
              />
            </Button>

            <hr className='mt-5 mb-3' />
            <div className='row mx-0'>
              <div className='col-6 pt-4 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-regular fa-handshake'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
              </div>
              <div className='col-6 pt-4 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-regular fa-file-powerpoint'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
              </div>
              <div className='col-6 pt-0 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-solid fa-question'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
              </div>
              <div className='col-6 pt-0 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-solid fa-check'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2018 </p>
              </div>
            </div>

            <hr className='mt-5 mb-3' />
            <h4 className='text-center text-dark fw-bold fs-5'> TYCA Qestions </h4>
            <div className='beta-btn py-2'>
              {' '}
              <Button
                className=' rounded-xs px-6 py-2 w-auto btn btn-primary btn btn-primary'
                type='button'
              >
                {' '}
                Beta 1{' '}
              </Button>{' '}
            </div>
          </div>

          <div className='col-md-6 col-lg-6 col-xl-4 py-8 px-3 mb-5 '>
            <h3 className='mb-7 fs-4'> Sadsad </h3>
            <div className='thumbnail-box mx-auto text-center'>
              <img
                src={toAbsoluteUrl('./media/avatars/default-user.png')}
                alt=''
                className='thumbnail-img'
              />
            </div>
            <p className='mt-3 fw-bold'> Gaurav Shah </p>
            <p className=' fw-semibold '> ICIWF </p>
            <p className=' fw-semibold '> Bsiness Category </p>
            <p>
              {' '}
              <i className='fa fa-globe'></i>
            </p>

            <hr className='mt-5 mb-3' />
            <div className='row mx-0'>
              <div className='col-6 pt-4 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-regular fa-handshake'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
              </div>
              <div className='col-6 pt-4 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-regular fa-file-powerpoint'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
              </div>
              <div className='col-6 pt-0 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-solid fa-question'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
              </div>
              <div className='col-6 pt-0 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-solid fa-check'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 27, 2019 </p>
              </div>
            </div>
          </div>

          <div className='col-md-6 col-lg-6 col-xl-4 py-8 px-3 mb-5 '>
            <h3 className='mb-7 fs-4'> Sadsad </h3>
            <div className='thumbnail-box mx-auto text-center'>
              <img
                src={toAbsoluteUrl('./media/avatars/default-user.png')}
                alt=''
                className='thumbnail-img'
              />
            </div>
            <p className='mt-3 fw-bold'> Gaurav Shah </p>
            <p className=' fw-semibold '> ICIWF </p>
            <p className=' fw-semibold '> Bsiness Category </p>
            <p>
              {' '}
              <i className='fa fa-globe'></i>
            </p>

            <hr className='mt-5 mb-3' />
            <div className='row mx-0'>
              <div className='col-6 pt-4 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-regular fa-handshake'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
              </div>
              <div className='col-6 pt-4 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-regular fa-file-powerpoint'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
              </div>
              <div className='col-6 pt-0 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-solid fa-question'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
              </div>
              <div className='col-6 pt-0 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-solid fa-check'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Jan 2, 2020 </p>
              </div>
            </div>
          </div>

          
          <div className='col-md-6 col-lg-6 col-xl-4 py-8 px-3 mb-5 '>
            <h3 className='mb-7 fs-4'> Presentation On Leadership </h3>
            <div className='thumbnail-box mx-auto text-center'>
              <img
                src={toAbsoluteUrl('./media/avatars/default-user.png')}
                alt=''
                className='thumbnail-img'
              />
            </div>
            <p className='mt-3 fw-bold'> Darshak Prajapati </p>
            <p className=' fw-semibold '> Sufalam Technology Pvt Ltd </p>
            <p className=' fw-semibold '> Information Technology's </p>
            <p>
              {' '}
              <i className='fa fa-globe'></i> Https://Sfalam.Atlassian.Net/
            </p>
            <Button className='eye-icon' type='button'>
              <img
                src={toAbsoluteUrl('./media/svg/files/pdf-white.svg')}
                alt=''
                style={{width: '20px'}}
              />
            </Button>

            <hr className='mt-5 mb-3'  />

            <div className='row mx-0'>
              <div className='col-6 pt-4 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-regular fa-handshake'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 2, 2022 </p>
              </div>
              <div className='col-6 pt-4 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-regular fa-file-powerpoint'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 2, 2022 </p>
              </div>
              <div className='col-6 pt-0 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-solid fa-question'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 2, 2022 </p>
              </div>
              <div className='col-6 pt-0 pb-3'>
                <Button className='card-icon' type='button'>
                  <i className='fa-solid fa-check'></i>
                </Button>
                <p className='text-center fs-7 fw-medium py-3 '> Dec 2, 2022 </p>
              </div>
            </div>

            <hr className='mt-5 mb-3' />
            <h4 className='text-center text-dark fw-bold fs-5'> TYCA Qestions </h4>
            <div className='beta-btn py-2'>
              {' '}
              <Button
                className=' rounded-xs px-6 py-2 w-auto btn btn-primary btn btn-primary'
                type='button'
              >
                {' '}
                Beta 1{' '}
              </Button>{' '}
            </div>
          </div>
        </div>
       
      </div>
    </>
  )
}

export {TycaPresentation}
