import React from 'react'

export function TermsConditionsPage() {
  return (
    <>
      <h1 className="inv-hst">Terms & Conditions </h1>

      <div className='row my-8 py-8 px-6 card leader-view'>
        <h2> Terms & Conditions will be updated soon! </h2>

        <p className='text-dark fw-normal fs-7 text-start mt-8'>*Terms & Conditions are subject to change without prior notice.</p>
      </div>
    </>
  )
}
