import React, {useEffect, useState} from 'react'
import {Button, Form, Image} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const ButtonStyle = {
  boxShadow: '0px 1px 2px 0 rgba(0, 0, 0, 0.2)',
}

export function SubmitMRTracking() {
  const [visible, setVisible] = useState(true)
  const goalListing = [
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
  ]
  useEffect(() => {
    const hideDivAfterTimeout = () => {
      setTimeout(() => {
        setVisible(false)
      }, 7000)
    }

    // Call the function to hide the div after the component mounts
    hideDivAfterTimeout()
  }, [])
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='SubmitMRTrackingModal'
        aria-hidden='true'
        aria-labelledby='SubmitMRTrackingModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='SubmitMRTrackingModalToggleLabel'>
                Submit MR
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {visible ? (
                <div className='single_comment_view d-flex align-items-start py-7'>
                  <div className='empty-comments text-center w-100'>
                    <Image
                      fluid
                      src={toAbsoluteUrl('/media/ilead/mr-empty.png')}
                      className='w-150px mb-4'
                    ></Image>
                    <h4 className='text-gray-900'>Set Goal and Track the Milestone</h4>
                    <span className='text-gray-500 d-block'>
                      Please add your goal first to track the milestone
                    </span>
                    <Button variant='primary' className='mt-7 d-inline px-7 py-3'>
                      Add Goal
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className='goal-listing d-flex align-items-start mb-3 flex-column'>
                    <Form.Label className='fw-bold mb-5'>Today 11 Jul 2023</Form.Label>
                    {goalListing.map((item: any) => (
                      <div className='goal_box mb-4 p-5 w-100'>
                        <p className='fw-bold fs-6'>{item.goaltitle}</p>
                        <hr />
                        <h3 className='fs-6 ilead-text'>Goal: {item.goal}</h3>
                        <hr />
                        <div className='d-flex justify-content-center'>
                          <Button
                            variant='light-primary'
                            className='btn-light-primary'
                            style={ButtonStyle}
                            data-bs-target='#MileStoneReachedModal'
                            data-bs-toggle='modal'
                            data-bs-dismiss='modal'
                          >
                            Submit MR
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Milestone Reached */}
      <div
        className='modal fade ilead-default-modal'
        id='MileStoneReachedModal'
        aria-hidden='true'
        aria-labelledby='MileStoneReachedModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='MileStoneReachedModalToggleLabel'>
                Milestone Reached
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {visible ? (
                <div className='single_comment_view d-flex align-items-start py-7'>
                  <div className='empty-comments text-center w-100'>
                    <Image
                      fluid
                      src={toAbsoluteUrl('/media/ilead/mr-empty.png')}
                      className='w-150px mb-4'
                    ></Image>
                    <h4 className='text-gray-900'>Set Goal and Track the Milestone</h4>
                    <span className='text-gray-500 d-block'>
                      Please add your goal first to track the milestone
                    </span>
                    <Button variant='primary' className='mt-7 d-inline px-7 py-3'>
                      Add Goal
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <div className='goal-listing d-flex align-items-start mb-3 flex-column'>
                    <Form.Label className='fw-bold mb-5'>Today 11 Jul 2023</Form.Label>
                    {goalListing.map((item: any) => (
                      <div className='goal_box mb-4 p-5 w-100'>
                        <p className='fw-bold fs-6'>{item.goaltitle}</p>
                        <hr />
                        <h3 className='fs-6 ilead-text'>Goal: {item.goal}</h3>
                        <hr />
                        <div className='d-flex justify-content-center'>
                          <Button
                            variant='light-primary'
                            className='btn-light-primary'
                            style={ButtonStyle}
                          >
                            Submit MR
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
  document.getElementById('element')
}
