import React, {useState} from 'react'
import {Button, Image} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu'
import 'react-horizontal-scrolling-menu/dist/styles.css'
import {LeftArrow, RightArrow} from './post-type/Arrow'

export function BiGi() {
  const memberBox = (
    <div className='member-box'>
      <div className='member-pic'>
        <Image
          src={toAbsoluteUrl('/media/avatars/300-1.jpg')}
          className='position-absolute w-100 h-100 object-fit-cover'
        ></Image>
      </div>
      <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
        <span className='points fw-bold ilead-text'>150</span>
        <h6 className='text-white text-truncate fw-normal mb-0'>Darshak Prajapati</h6>
      </div>
    </div>
  )
  type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>
  function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 6

    if (isThouchpad) {
      ev.stopPropagation()
      return
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext()
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev()
    }
  }
  const allMemberDetails = [
    {point: 150, name: 'Darshak Prajapati', photo: '/media/avatars/300-1.jpg'},
    {point: 130, name: 'Milap Gajjar', photo: '/media/avatars/300-2.jpg'},
    {point: 120, name: 'Raj Jangid', photo: '/media/avatars/300-3.jpg'},
    {point: 100, name: 'Krunal Kapadiya', photo: '/media/avatars/300-4.jpg'},
    {point: 90, name: 'Pranav Thakker', photo: '/media/avatars/300-5.jpg'},
  ]

  const myGroupMemberDetails = [
    {point: 150, name: 'Irfan Mukhi', photo: '/media/avatars/300-6.jpg'},
    {point: 130, name: 'Aiyub Panara', photo: '/media/avatars/300-7.jpg'},
    {point: 120, name: 'Tauqir Qureshi', photo: '/media/avatars/300-8.jpg'},
    {point: 100, name: 'Ashok Waghela', photo: '/media/avatars/300-9.jpg'},
    {point: 90, name: 'Akib Dahya', photo: '/media/avatars/300-10.jpg'},
  ]

  const [allBi, setAllBi] = useState(true)
  return (
    <div className='gibi-container card sticky-md-top'>
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6 position-absolute position-md-relative end-0 top-0 border-0 pe-5 pt-3'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_4'>
            BI'23
            <br className='d-block d-md-none' />0
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
            Jun'23
            <br className='d-block d-md-none' /> GI 10
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        {/* begin:BI Members */}
        <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
          {/* begin:header */}
          <div className='d-flex align-items-center justify-content-between'>
            <div className='fw-bold fs-6'>Star Member Leaders</div>
            <div>
              <Button
                variant='link'
                className={allBi ? 'me-5 active' : 'me-5'}
                onClick={() => {
                  setAllBi(true)
                }}
              >
                All
              </Button>
              <Button
                variant='link'
                className={!allBi ? 'active' : ''}
                onClick={() => {
                  setAllBi(false)
                }}
              >
                My Groups
              </Button>
            </div>
          </div>
          {/* end:header */}
          {/* begin:member listing */}
          {allBi ? (
            <div className='all bigi-members'>
              {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
                {items.map(({id}) => (
                  <Card
                    title={id}
                    itemId={id} // NOTE: itemId is required for track items
                    key={id}
                  />
                ))}
              </ScrollMenu> */}
              <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
                {allMemberDetails.map((item: any) => (
                  <div className='member-box'>
                    <div className='member-pic'>
                      <Image
                        src={item.photo}
                        className='position-absolute w-100 h-100 object-fit-cover'
                      ></Image>
                    </div>
                    <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                      <span className='points fw-bold ilead-text'>{item.point}</span>
                      <h6 className='text-white text-truncate fw-normal mb-0'>{item.name}</h6>
                    </div>
                  </div>
                ))}
              </ScrollMenu>
            </div>
          ) : (
            <div className='mygroup bigi-members'>
              {/* begin:member box */}
              <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
                {myGroupMemberDetails.map((item: any) => (
                  <div className='member-box'>
                    <div className='member-pic'>
                      <Image
                        src={item.photo}
                        className='position-absolute w-100 h-100 object-fit-cover'
                      ></Image>
                    </div>
                    <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                      <span className='points fw-bold ilead-text'>{item.point}</span>
                      <h6 className='text-white text-truncate fw-normal mb-0'>{item.name}</h6>
                    </div>
                  </div>
                ))}
              </ScrollMenu>
              {/* end:member box */}
            </div>
          )}
          {/* end:member listing */}
        </div>
        {/* end:BI Members */}
        <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
          {/* begin:header */}
          <div className='d-flex align-items-center justify-content-between'>
            <div className='fw-bold fs-6'>Star Member Leaders</div>
            <div>
              <Button
                variant='link'
                className={allBi ? 'me-5 active' : 'me-5'}
                onClick={() => {
                  setAllBi(true)
                }}
              >
                All
              </Button>
              <Button
                variant='link'
                className={!allBi ? 'active' : ''}
                onClick={() => {
                  setAllBi(false)
                }}
              >
                My Groups
              </Button>
            </div>
          </div>
          {/* end:header */}
          {/* begin:member listing */}
          {allBi ? (
            <div className='all bigi-members'>
              {/* begin:member box */}
              <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
                {allMemberDetails.map((item: any) => (
                  <div className='member-box'>
                    <div className='member-pic'>
                      <Image
                        src={item.photo}
                        className='position-absolute w-100 h-100 object-fit-cover'
                      ></Image>
                    </div>
                    <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                      <span className='points fw-bold ilead-text'>{item.point}</span>
                      <h6 className='text-white text-truncate fw-normal mb-0'>{item.name}</h6>
                    </div>
                  </div>
                ))}
              </ScrollMenu>
              {/* end:member box */}
            </div>
          ) : (
            <div className='all bigi-members'>
              {/* begin:member box */}
              <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={onWheel}>
                {myGroupMemberDetails.map((item: any) => (
                  <div className='member-box'>
                    <div className='member-pic'>
                      <Image
                        src={item.photo}
                        className='position-absolute w-100 h-100 object-fit-cover'
                      ></Image>
                    </div>
                    <div className='member-details position-relative p-3 w-100 h-100 d-flex flex-column justify-content-end'>
                      <span className='points fw-bold ilead-text'>{item.point}</span>
                      <h6 className='text-white text-truncate fw-normal mb-0'>{item.name}</h6>
                    </div>
                  </div>
                ))}
              </ScrollMenu>
              {/* end:member box */}
            </div>
          )}
          {/* end:member listing */}
        </div>
      </div>
    </div>
  )
}
