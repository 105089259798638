import React, {} from 'react'
import {Image} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

export function DocumentPost() {
  return (
    <>
      <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
        <div className='post-content'>
          TYCA Presentation for Lr. Mustufa Shaikh with the title "Sufalam" has been uploaded.
        </div>
      </div>
      <div className='media mb-2'>
        <a href='https://www.ileadsampark.org/home/dashboard' target='_blank' rel="noreferrer">
          <Image src={toAbsoluteUrl('media/ilead/pdf.png')} className='d-block w-100'></Image>
        </a>
      </div>
      {/* end:post */}
    </>
  )
}
