import React, {useState} from 'react'
import {Button, Form, InputGroup} from 'react-bootstrap'
import {SearchLeaderListing} from './SearchLeaderListing'

export function GroupMeetingReport() {
  const [searchLeaderListing, setsearchLeaderListing] = useState(true)
  return (
    <div
      className='modal fade ilead-default-modal'
      id='SubmitGroupMeetingReportModal'
      aria-hidden='true'
      aria-labelledby='SubmitGroupMeetingReportModalLabel'
    >
      <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
        <div className='modal-content'>
          <div className='modal-header py-5'>
            <h3 className='modal-title fs-3 fw-bold' id='SubmitGroupMeetingReportModalToggleLabel'>
              Submit Group Meeting Report
            </h3>
            <button
              type='button'
              className='btn-close text-white'
              data-bs-target='#write_post_modal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
            ></button>
          </div>
          <div className='modal-body pt-7 write-post-wrapper'>
            {searchLeaderListing ? (
              <>
                <Form className='mb-7'>
                  <Form.Label className='text-gray-700 mb-7'>Report Submitted For Group</Form.Label>
                  <Form.Group className='mb-4'>
                    <Form.Control
                      type='text'
                      id='chaptername'
                      className='ilead-input mb-2'
                      placeholder='Chapter'
                      value='Beta 1'
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label>
                      Agenda of the meeting <span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    <Form.Select aria-label='meeting agenfa' className='ilead-input'>
                      <option>Select Option</option>
                      <option value='1'>TYCA Discussion</option>
                      <option value='2'>Goal Review</option>
                      <option value='3'>Other</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label>
                      Meeting Start Time <span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    <Form.Select aria-label='meeting agenfa' className='ilead-input'>
                      <option>Select Option</option>
                      <option value='1'>TYCA Discussion</option>
                      <option value='2'>Goal Review</option>
                      <option value='3'>Other</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label>
                      Meeting End Time <span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    <Form.Select aria-label='meeting agenfa' className='ilead-input'>
                      <option>Select Option</option>
                      <option value='1'>TYCA Discussion</option>
                      <option value='2'>Goal Review</option>
                      <option value='3'>Other</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-7 d-flex flex-column'>
                    <Form.Label className='mb-5'>
                      Participant <span style={{color: 'red'}}> *</span>
                    </Form.Label>
                    <Form.Check
                      reverse
                      type='checkbox'
                      name='participantleader'
                      id='leadername1'
                      label='Nirav Agarvat'
                      className='w-100 text-start mb-5'
                    />
                    <Form.Check
                      reverse
                      type='checkbox'
                      name='participantleader'
                      id='leadername2'
                      label='Gaurav Shah'
                      className='w-100 text-start mb-5'
                      // npm i react-native-modal-datetime-picker
                    />
                    <Form.Check
                      reverse
                      type='checkbox'
                      name='participantleader'
                      id='leadername3'
                      label='Milap Gajjar'
                      className='w-100 text-start mb-5'
                    />
                    <Form.Check
                      reverse
                      type='checkbox'
                      name='participantleader'
                      id='leadername4'
                      label='Chatan Chavda'
                      className='w-100 text-start mb-5'
                    />
                    <Form.Check
                      reverse
                      type='checkbox'
                      name='participantleader'
                      id='leadername5'
                      label='Irfan Mukhi'
                      className='w-100 text-start mb-5'
                    />
                  </Form.Group>
                  <Form.Group className='mb-4 mt-7'>
                    <Form.Label>What went well / What can be improved</Form.Label>
                    <div className='whatwentwellbox p-4 w-100 mt-4'>
                      <div className='bt-switch d-flex justify-content-between align-items-center'>
                        <Form.Label className='w-100 text-black pe-5'>
                          Meeting Scheduled in advance.
                        </Form.Label>
                        <Form.Check
                          reverse
                          type='switch'
                          id='meeting-scheduled'
                          label='Went well?'
                          className='d-flex flex-column flex-column-reverse pe-0 text-nowrap'
                        />
                      </div>
                    </div>
                    <div className='whatwentwellbox p-4 w-100 mt-4'>
                      <div className='bt-switch d-flex justify-content-between align-items-center'>
                        <Form.Label className='w-100 text-black pe-5'>
                          Meeting agenda communicated in advance
                        </Form.Label>
                        <Form.Check
                          reverse
                          type='switch'
                          id='meeting-agenda'
                          label='Went well?'
                          className='d-flex flex-column flex-column-reverse pe-0 text-nowrap'
                        />
                      </div>
                    </div>
                    <div className='whatwentwellbox p-4 w-100 mt-4'>
                      <div className='bt-switch d-flex justify-content-between align-items-center'>
                        <Form.Label className='w-100 text-black pe-5'>
                          Meeting started on time.
                        </Form.Label>
                        <Form.Check
                          reverse
                          type='switch'
                          id='meeting-ontime'
                          label='Went well?'
                          className='d-flex flex-column flex-column-reverse pe-0 text-nowrap'
                        />
                      </div>
                    </div>
                    <div className='whatwentwellbox p-4 w-100 mt-4'>
                      <div className='bt-switch d-flex justify-content-between align-items-center'>
                        <Form.Label className='w-100 text-black pe-5'>
                          All the participants arrived on time.
                        </Form.Label>
                        <Form.Check
                          reverse
                          type='switch'
                          id='meeting-participants'
                          label='Went well?'
                          className='d-flex flex-column flex-column-reverse pe-0 text-nowrap'
                        />
                      </div>
                    </div>
                    <div className='whatwentwellbox p-4 w-100 mt-4'>
                      <div className='bt-switch d-flex justify-content-between align-items-center'>
                        <Form.Label className='w-100 text-black pe-5'>
                          Meeting remained focused on the agenda.
                        </Form.Label>
                        <Form.Check
                          reverse
                          type='switch'
                          id='meeting-focused'
                          label='Went well?'
                          className='d-flex flex-column flex-column-reverse pe-0 text-nowrap'
                        />
                      </div>
                    </div>
                    <div className='whatwentwellbox p-4 w-100 mt-4'>
                      <div className='bt-switch d-flex justify-content-between align-items-center'>
                        <Form.Label className='w-100 text-black pe-5'>
                          Meeting completed on time.
                        </Form.Label>
                        <Form.Check
                          reverse
                          type='switch'
                          id='meeting-completed'
                          label='Went well?'
                          className='d-flex flex-column flex-column-reverse pe-0 text-nowrap'
                        />
                      </div>
                    </div>
                    <div className='whatwentwellbox p-4 w-100 mt-4'>
                      <div className='bt-switch d-flex justify-content-between align-items-center'>
                        <Form.Label className='w-100 text-black pe-5'>
                          Minutes of meeting has been prepared.
                        </Form.Label>
                        <Form.Check
                          reverse
                          type='switch'
                          id='meeting-mom'
                          label='Went well?'
                          className='d-flex flex-column flex-column-reverse pe-0 text-nowrap'
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className='mb-4' controlId='anycomments'>
                    <Form.Label>Any Comments:</Form.Label>
                    <Form.Control as='textarea' rows={3} />
                    <p className='text-gray-500 fs-13 mt-2'>Available Characters 300 </p>
                  </Form.Group>
                  <Form.Group className='mb-7 mt-7'>
                    <h5 className='mt-7 mb-7'>Add Attachment</h5>
                    <Button variant='primary' className='me-3 px-4 py-3'>
                      CAMERA
                    </Button>
                    <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                      <Form.Control
                        type='file'
                        className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                      ></Form.Control>
                      GALLERY
                    </div>
                  </Form.Group>
                  <Form.Group className='mb-5'>
                    <div className='name-wrapper'>
                      <Form.Label className='name-title fs-14'>Submitted By:</Form.Label>
                      <h2 className='fw-semibold mb-3'>Nirav Agarvat</h2>
                    </div>
                  </Form.Group>
                  <div className='note-description mt-7 fs-6 text-gray-700'>
                    NOTE : Points will be counted for Sunday to Saturday - 2nd and 4th Week of the
                    month only.
                  </div>
                  <Form.Group className='mt-5 d-flex'>
                    <Button variant='primary' type='submit' className='w-100'>
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
              </>
            ) : (
              <SearchLeaderListing onClick={setsearchLeaderListing}></SearchLeaderListing>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
