import React from 'react'
import { DefaultPost } from './post-type/DefaultPost'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function PostListing() {
  return (
    <div className='post-listing position-relative'>
      <DefaultPost></DefaultPost>
    </div>
  )
}
