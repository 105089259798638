import React, { useEffect, useState } from "react";


const ButtonStyle = {
    boxShadow: '0px 1px 2px 0 rgba(0, 0, 0, 0.2)',
  }

const ManageGoal = () =>{

    const [visible, setVisible] = useState(true)
  const goalListing = [
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
    {
      goaltitle: 'My Goal is to Reduce weight from 80 to 75 by Aug 12, 2023',
      goal: '75',
    },
  ]


  useEffect(() => {
    const hideDivAfterTimeout = () => {
      setTimeout(() => {
        setVisible(false)
      }, 7000)
    }

    // Call the function to hide the div after the component mounts
    hideDivAfterTimeout()
  }, [])

return(

    <> 
<div className="modal fade manage-goal-modal"
 id="ManageGoalModal"
  tabIndex={-1} 
  aria-labelledby="ManageGoalModalLabel" 
  aria-hidden="true">

  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel"> Manage Goal </h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body pt-7 write-post-wrapper">
         <div className="row mx-0 p-3">
           <div className="col-8">
            <p  className="fw-bold text-start"> My Goad is to Reduce weight from 80 to 75 by Jun 12, 2022 </p>
           </div>
           <div className="col-4 pt-3 d-flex text-end">
            <span className="goal-edit-icon me-3"> <i className="fa-solid fa-pen"></i> </span>
            <span className="goal-delete-icon"> <i className="fa-solid fa-trash-can"></i> </span>
           </div>
          <div className="col-12 d-flex justify-content-between row-footer mt-3">
            <div className="col-6 text-normal fs-7 text-left goal-count"> Goal: 75 </div>
            <div className="col-6 text-normal fs-7 text-end text-dark"> by : Jun 12, 2022 </div>
          </div>
         </div>

         <div className="row mx-0 p-3">
           <div className="col-8">
            <p  className="fw-bold text-start"> My Goad is to read book of pages from 2 to 10 by Mar 5, 2022 </p>
           </div>
           <div className="col-4 pt-3 d-flex text-end">
            <span className="goal-edit-icon me-3"> <i className="fa-solid fa-pen"></i> </span>
            <span className="goal-delete-icon"> <i className="fa-solid fa-trash-can"></i> </span>
           </div>
          <div className="col-12 d-flex justify-content-between row-footer mt-3">
            <div className="col-6 text-normal fs-7 text-left goal-count"> Goal: 10 </div>
            <div className="col-6 text-normal fs-7 text-end text-dark"> by : Mar 5, 2022 </div>
          </div>
         </div>

         <div className="row mx-0 p-3">
           <div className="col-8">
            <p  className="fw-bold text-start"> My Goad is to abc from a to b by Jul 22, 2022 </p>
           </div>
           <div className="col-4 pt-3 d-flex text-end">
            <span className="goal-edit-icon me-3"> <i className="fa-solid fa-pen"></i> </span>
            <span className="goal-delete-icon"> <i className="fa-solid fa-trash-can"></i> </span>
           </div>
          <div className="col-12 d-flex justify-content-between row-footer mt-3">
            <div className="col-6 text-normal fs-7 text-left goal-count"> Goal: b </div>
            <div className="col-6 text-normal fs-7 text-end text-dark"> by : Jul 22, 2022 </div>
          </div>
         </div>
      </div>
      <div className="modal-footer">
        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
        <button type="button" className="btn btn-primary w-100 rounded-sm"> Add Goal </button>
      </div>
    </div>
  </div>
</div>

    </>
)


}

export {ManageGoal}