import React, {useState} from 'react'
import {Button, Form, Image, InputGroup} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'

export function BusinessReferral() {
  const [businessReferralStepOne, setbusinessReferralStepOne] = useState(true)
  const leaderListing = [
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-1.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-2.jpg',
    },
    {
      name: 'Amit Wadhwa',
      companyname: 'Wadhwas House Of Embroidery Pvt. Ltd.',
      photo: '/media/avatars/300-3.jpg',
    },
    {
      name: 'Anil Agrawal',
      companyname: 'Kasba Paridhan Pvt Ltd',
      photo: '/media/avatars/300-4.jpg',
    },
    {
      name: 'Anil Gupta',
      companyname: 'Unnati Unlimited PVT LTD',
      photo: '/media/avatars/300-5.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-6.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-7.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-1.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-2.jpg',
    },
    {
      name: 'Amit Wadhwa',
      companyname: 'Wadhwas House Of Embroidery Pvt. Ltd.',
      photo: '/media/avatars/300-3.jpg',
    },
    {
      name: 'Anil Agrawal',
      companyname: 'Kasba Paridhan Pvt Ltd',
      photo: '/media/avatars/300-4.jpg',
    },
    {
      name: 'Anil Gupta',
      companyname: 'Unnati Unlimited PVT LTD',
      photo: '/media/avatars/300-5.jpg',
    },
    {
      name: 'Akash Goyal',
      companyname: 'Shivalik Fabrics Pvt.Ltd.',
      photo: '/media/avatars/300-6.jpg',
    },
    {
      name: 'Amit Nagrath',
      companyname: 'Nagrath Aluminium Pvt. Ltd.',
      photo: '/media/avatars/300-7.jpg',
    },
  ]
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='BusinessReferrralModal'
        aria-hidden='true'
        aria-labelledby='BusinessReferrralModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='BusinessReferrralModalToggleLabel'>
                Business Referral
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {businessReferralStepOne ? (
                <>
                  <h3 className='text-black fw-bold fs-4 mb-6'>Search Leader</h3>
                  <Form.Group className='mb-7'>
                    <Form.Select aria-label='Default select example' className='ilead-input'>
                      <option>Select Chapter</option>
                      <option value='1'>Trustees</option>
                      <option value='2'>Eklavya</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>Search Leader</Form.Label>
                    <InputGroup>
                      <InputGroup.Text id='SearchLeader' className='bg-white ilead-input-group'>
                        <i className='fa-solid fa-magnifying-glass'></i>
                      </InputGroup.Text>
                      <Form.Control
                        placeholder='Type leader name'
                        aria-label='LeaderSearch'
                        aria-describedby='LeaderSearch'
                        className='border-start-0 ps-0 ilead-input-group'
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className='leader_list_scroll_wrapper mt-7'>
                    {/* begin: leader box */}
                    {leaderListing.map((item: any) => (
                      <div
                        className='small_leader_box d-flex align-items-center mb-5 cursor-pointer'
                        onClick={() => {
                          setbusinessReferralStepOne(false)
                        }}
                      >
                        <div className='symbol symbol-45px me-3'>
                          <Image src={item.photo}></Image>
                        </div>
                        <div className='leader_details'>
                          <div className='text-black fs-6 fs-md-5 fw-bold mb-0'>{item.name}</div>
                          <span className='fs-14 text-gray-600'>{item.companyname}</span>
                        </div>
                      </div>
                    ))}
                    {/* end: leader box */}
                  </div>
                </>
              ) : (
                <>
                  <div className='business-ref-ineer'>
                    <div className='border-bottom'>
                      <div className='position-absolute'>
                        <Image
                          src={toAbsoluteUrl('/media/logos/ilead-logo.jpg')}
                          className='mh-30px'
                        ></Image>
                      </div>
                      <div className='ref-icon text-center'>
                        <Image src={toAbsoluteUrl('/media/ilead/business-referral.svg')}></Image>
                      </div>
                      <h5 className='text-center text-black fs-3 fw-bold'>Darshak Prajapati</h5>
                      <p className='mb-1 mt-4 text-center fs-6 fw-semibold'>
                        Gives Business Referral for <br />
                        Leader
                      </p>
                      <div className='mt-4 gratitude-title ilead-text w-100 p-3 d-block text-center fw-semibold'>
                        Chetan Chavda
                      </div>
                    </div>
                    <Form className='mt-7'>
                      <Form.Group className='mb-4'>
                        <Form.Label htmlFor='name' className='fs-14'>
                          Name Referred <span style={{color: 'red'}}> *</span>
                        </Form.Label>
                        <InputGroup className='mb-3'>
                          <Form.Control
                            placeholder='Enter name'
                            aria-label='name'
                            id='name'
                            className='border-right-0 ilead-input'
                          />
                          <InputGroup.Text id='name' className='bg-white ilead-input-group'>
                            <i className='fa-solid fa-address-book'></i>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className='mb-4'>
                        <Form.Label htmlFor='contactDetails' className='fs-14'>
                          Contact Details <span style={{color: 'red'}}> *</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          id='contactDetails'
                          className='ilead-input'
                          placeholder='Enter contact number'
                        />
                      </Form.Group>
                      <Form.Group className='mb-4'>
                        <Form.Label htmlFor='workRequired' className='fs-14'>
                          Work Required <span style={{color: 'red'}}> *</span>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          id='workRequired'
                          className='ilead-input'
                          placeholder='Enter required work'
                        />
                      </Form.Group>
                      <Form.Group className='mt-5 d-flex'>
                        <Button
                          variant='secondary'
                          type='button'
                          className='w-50 me-3'
                          onClick={() => {
                            setbusinessReferralStepOne(true)
                          }}
                        >
                          Cancel
                        </Button>
                        <Button variant='primary' type='submit' className='w-50'>
                          Submit
                        </Button>
                      </Form.Group>
                    </Form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
