import React from 'react'
import {Button} from 'react-bootstrap'
import {FilterDropdown} from './FilterDropdown'
 import {ScrollContainer} from 'react-indiana-drag-scroll'
import 'react-indiana-drag-scroll/dist/style.css'

export function Filter() {
  return (
    <div className='post-filter card bg-white p-3 d-flex flex-nowrap flex-row mb-1'>
      <div className='main-filter'>
        <ScrollContainer>
          <div className='d-flex flex-nowrap'>
            <Button variant='primary' className='text-nowrap me-1 ps-7 py-2 active'>
              All
            </Button>
            <Button variant='primary' className='text-nowrap me-1 ps-7 py-2'>
              Announcement
            </Button>
            <Button variant='primary' className='text-nowrap me-1 ps-7 py-2'>
              Ask Help
            </Button>
            <Button variant='primary' className='text-nowrap me-1 ps-7 py-2'>
              Tracksheet
            </Button>
            <Button variant='primary' className='text-nowrap ps-7 py-2'>
              Bookmarked Post
            </Button>
          </div>
        </ScrollContainer>
      </div>
      <div className='more-filte'>
        <div className='m-0'>
          <button
            type='button'
            className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <i className='bi bi-funnel fs-2x'></i>
          </button>

          <FilterDropdown />
        </div>
      </div>
    </div>
  )
}
