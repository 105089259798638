/* eslint-disable react/jsx-no-target-blank */
import React, {useRef} from 'react'
import {useIntl} from 'react-intl'
// import {KTIcon} from '../../../../helpers'
// import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarLogo} from '../SidebarLogo'
import {Button, Image} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../helpers'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {logout} = useAuth()
  const {config} = useLayout()
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : ''

  return (
    <>
      <div className='user-menu mb-lg-3 mt-lg-3 px-xl-4 py-lg-5'>
        <Link to='/dashboard' className='d-none d-md-block mb-4 text-center'>
          {config.layoutType === 'dark-sidebar' ? (
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/ilead-logo-white.png')}
              className='h-45px app-sidebar-logo-default full-logo'
            />
          ) : (
            <>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/ilead-logo-white.png')}
                className='h-45px app-sidebar-logo-default theme-light-show full-logo'
              />
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/ilead-logo-white.png')}
                className='h-45px app-sidebar-logo-default theme-dark-show full-logo'
              />
            </>
          )}
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/ilead-logo-white-small.png')}
            className='h-40px app-sidebar-logo-minimize small-logo'
          />
        </Link>
        <div className='d-md-none bg-ilead mb-4'>
          <div className='d-flex'>
            <div className='symbol symbo-70'>
              <Image src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
            </div>
            <div className='user-details ps-4 flex-1'>
              <h4 className='fs-6 text-white'>Darshak Prajapati</h4>
              <span className='text-white fs-13 d-block'>Sufalam Techonologies</span>
              <span className='text-white fs-13 d-block'>Chapter: Beta - Beta 1</span>
            </div>
            <div className='info-icon'>
              <Button variant='link' className='text-end text-white'>
                <i className='fa-solid fa-circle-info text-white'></i>
              </Button>
            </div>
          </div>
        </div>
        <SidebarMenuItem
          to='/dashboard'
          icon='home-2'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        />
        <SidebarMenuItem to='/invitation-history' icon='send' title='Invitation History' />
        <SidebarMenuItem to='/leaders' icon='magnifier' title='Search Leader' />
        <SidebarMenuItem to='/library' icon='book-open' title='Library' />
        <SidebarMenuItem to='/iciwf-uupl' icon='award' title='ICIWF/UUPL' />
        <SidebarMenuItem to='/terms-conditions' icon='document' title='Terms & Conditions' />
        <SidebarMenuItem to='/feedback' icon='messages' title='Feedback / Query' />
        <SidebarMenuItem to='/leader-profile' icon='profile-circle' title='My Profile' />
        <SidebarMenuItem to='/directory' icon='profile-user' title='Directory' />
        <SidebarMenuItem to='/tyca' icon='questionnaire-tablet' title='TYCA' />
        <SidebarMenuItem to='/index' icon='chart-line-up-2' title='Index' />
        <SidebarMenuItem
          to='#'
          icon='exit-left'
          title='Sign Out'
          action='button'
          logout={() => logout()}
        />
      </div>
      <div className='admin-menu px-xl-3 py-lg-5'>
        <h5 className='text-white ps-7 mt-5 mt-md-0'>Administrator</h5>
        <SidebarMenuItem
          to='https://www.ileadsampark.org/home/stat'
          icon='questionnaire-tablet'
          title='Session Summary'
        />
        <SidebarMenuItem
          to='https://www.ileadsampark.org/home/monthlypoints'
          icon='graph-up'
          title='Monthly Report'
        />
        <SidebarMenuItem
          to='https://www.ileadsampark.org/session-attendance?token=jY6WsbjdjF2Eb9GgAfGBX7riCAGMiPWxyLazxqQVL8k15pNe5CC8BGO9vCAIyZ4B&userid=1&mapikey=hello'
          icon='people'
          title='Session Attendance'
        />
        <SidebarMenuItem
          to='https://www.ileadsampark.org/visitor_panel?token=jY6WsbjdjF2Eb9GgAfGBX7riCAGMiPWxyLazxqQVL8k15pNe5CC8BGO9vCAIyZ4B&userid=1&mapikey=hello'
          icon='user-tick'
          title='Visitor Panel'
        />
        <SidebarMenuItem
          to='https://www.ileadsampark.org/home/librarymanagement'
          icon='book'
          title='Library Management'
        />
        <SidebarMenuItem
          to='https://www.ileadsampark.org/usermanagement?token=jY6WsbjdjF2Eb9GgAfGBX7riCAGMiPWxyLazxqQVL8k15pNe5CC8BGO9vCAIyZ4B&userid=1&mapikey=hello'
          icon='profile-user'
          title='User Management'
        />
        <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' />
      </div>
      {/*<div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
