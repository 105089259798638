import React, {useState, useEffect} from 'react'
import {Image} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export function PostHeader({
  pinned,
  grouppost,
  privatepost,
  poststypeannoucement,
  poststypehelp,
  poststypeshareidea,
  poststypethankyou,
  poststypebusinessreferral,
  poststypeinvitevisitor,
  poststypetracksheet,
  poststypefeedback,
  poststypetyca,
  poststypegroupmeetingreport,
  poststypesessionflow,
  poststypetycaquestion,
  poststypebusinessconnect,
  poststypebusinessgenerated,
  poststypemrtracking,
  posttypebirthdaypost,
  commentview,
}: any) {
  const [bookMarkedPost, setBookMarkedPost] = useState(true)
  const [skeletonLoading, setSkeletonLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setSkeletonLoading(false)
    }, 2000)
  }, [])
  return (
    <div className='card-header p-4 align-items-start'>
      {pinned ? (
        <span className='fs-13 text-gray-600 w-100 mb-2'>
          <i className='bi bi-pin-fill'></i> Pinned Annoucement
        </span>
      ) : (
        <></>
      )}
      <div className='d-flex align-items-start'>
        <div
          className={
            !commentview
              ? 'symbol symbol-md-55px me-3 top-leader-profile'
              : 'symbol symbol-md-45px me-3 top-leader-profile'
          }
        >
          {skeletonLoading ? (
            <Skeleton height='100%' containerClassName='avatar-skeleton w-50px h-50px d-block' />
          ) : (
            <Image src={toAbsoluteUrl('/media/avatars/300-3.jpg')}></Image>
          )}
        </div>

        <div className='flex-grow-1'>
          <Link to='' className='text-black text-hover-primary fs-5 fs-md-4 fw-bold'>
            {skeletonLoading ? <Skeleton containerClassName='' width={'100%'} /> : 'Nirav Agarvat'}
          </Link>
          <span className='text-gray-400 fw-semibold d-block fs-13'>
            {skeletonLoading ? (
              <Skeleton containerClassName='' width={'100%'} />
            ) : (
              'Sufalam Technologies'
            )}
          </span>
          <div className='d-flex align-items-center'>
            <span className='text-gray-400 fw-semibold d-block fs-13 me-3'>5 hours ago</span>
            <span className='round-separator me-3'></span>
            {grouppost ? (
              <Image src={toAbsoluteUrl('/media/ilead/groupicon.png')} height={13}></Image>
            ) : privatepost ? (
              <Image src={toAbsoluteUrl('/media/ilead/privatepost.png')} height={13}></Image>
            ) : (
              <Image src={toAbsoluteUrl('/media/ilead/publicpost.png')} height={13}></Image>
            )}
          </div>
        </div>
      </div>
      <div className='card-toolbar'>
        <div className='m-0 d-flex flex-column align-items-end'>
          {bookMarkedPost ? (
            <i
              className='fa-regular fa-bookmark fs-2x cursor-pointer text-black'
              onClick={() => {
                setBookMarkedPost(false)
              }}
            ></i>
          ) : (
            <i
              className='fa-solid fa-bookmark fs-2x cursor-pointer ilead-text'
              onClick={() => {
                setBookMarkedPost(true)
              }}
            ></i>
          )}
          {poststypeannoucement ? (
            <span className='mt-2 fs-13 post-type text-end'>Annoucement</span>
          ) : poststypehelp ? (
            <span className='mt-2 fs-13 post-type text-end'>Help / Referral</span>
          ) : poststypeshareidea ? (
            <span className='mt-2 fs-13 post-type text-end'>Ideas/Achievement</span>
          ) : poststypethankyou ? (
            <span className='mt-2 fs-13 post-type text-end'>Thank You</span>
          ) : poststypebusinessreferral ? (
            <span className='mt-2 fs-13 post-type text-end'>Business Referral</span>
          ) : poststypetracksheet ? (
            <span className='mt-2 fs-13 post-type text-end'>Tracksheet</span>
          ) : poststypeinvitevisitor ? (
            <span className='mt-2 fs-13 post-type text-end'>Visitor Invitation</span>
          ) : poststypefeedback ? (
            <span className='mt-2 fs-13 post-type text-end'>Feedback</span>
          ) : poststypetyca ? (
            <span className='mt-2 fs-13 post-type text-end'>TYCA Presentation Updates</span>
          ) : poststypegroupmeetingreport ? (
            <span className='mt-2 fs-13 post-type text-end'>Group Meeting Updates</span>
          ) : poststypesessionflow ? (
            <span className='mt-2 fs-13 post-type text-end'>Session Flow</span>
          ) : poststypetycaquestion ? (
            <span className='mt-2 fs-13 post-type text-end'>TYCA Question</span>
          ) : poststypebusinessconnect ? (
            <span className='mt-2 fs-13 post-type text-end'>Business Connect</span>
          ) : poststypebusinessgenerated ? (
            <span className='mt-2 fs-13 post-type text-end'>Business Generated</span>
          ) : poststypemrtracking ? (
            <span className='mt-2 fs-13 post-type text-end'>MR Tracking</span>
          ) : posttypebirthdaypost ? (
            <span className='mt-2 fs-13 post-type text-end'>Birthday</span>
          ) : (
            <span className='mt-2 fs-13 post-type text-end'>I can I will</span>
          )}
        </div>
      </div>
    </div>
  )
}
