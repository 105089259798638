/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {BiGi} from './components/BiGi'
// import {Filter} from './components/Filter'
import {PostListing} from './components/PostListing'
import {WritePostModal} from './components/create-post/WritePostModal'
import { Filter } from './components/filter'
// import { Filter } from './components/filter'
// import { Filter } from './components/Filter'

const DashboardPage: FC = () => (
  <>
    <div className='row flex-row flex-row-reverse mobile-noMargin justify-content-center'>
      <div className='col-xl-6 right-wrapper mb-1' style={{maxWidth:'600px'}}>
        <BiGi></BiGi>
      </div>
      <div className='col-xl-6 dashboard-wrapper' style={{maxWidth:'600px'}}>
        <Filter></Filter>
        <PostListing></PostListing>
        <WritePostModal></WritePostModal>
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  return <DashboardPage />
}

export {DashboardWrapper}
