import React from 'react'
import { ResetDateChapter } from './ResetDateChapter'


const InvitationHistory: React.FC = () => {

  return (
<>
    <h1 className='inv-hst'>Invitation History</h1>


    <div className='card p-8 mt-5 invitation-history'>

    <table className='table table-striped' >
  <thead>
    <tr>
      <th scope="col">Event Date</th>
      <th scope="col">Name</th>
      <th scope="col">Mobile Number</th>
      <th scope="col">Email</th>
      <th scope="col">Company</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td data-label="Event Date">07-02-2022</td>
      <td data-label="Name">vshsbsbh</td>
      <td data-label="Mobile Number">36377588436</td>
      <td data-label="Email">vshsbsbh@gmail.com</td>
      <td data-label="Company"> Xyzabc</td>
      <td data-label="Action"> 
      <button type="button" 
       data-bs-toggle='modal'
       data-bs-target='#reset_date_chapter_modal'
      className="btn btn-success rounded-circle py-2 ps-3 pe-2 invitation-resend-btn"
      >
        <i className="fa fa-undo"></i></button>
         </td>
    </tr>
    <tr>
      <td data-label="Event Date">07-02-2022</td>
      <td data-label="Name">vshsbsbh</td>
      <td data-label="Mobile Number">36377588436</td>
      <td data-label="Email">vshsbsbh@gmail.com</td>
      <td data-label="Company"> Xyzabc</td>
      <td data-label="Action"> 
      <button type="button"
       data-bs-toggle='modal'
       data-bs-target='#reset_date_chapter_modal' className="btn btn-success rounded-circle py-2 ps-3 pe-2 invitation-resend-btn" ><i className="fa fa-undo"></i></button> </td>
    </tr>
    <tr>
      <td data-label="Event Date">07-02-2022</td>
      <td data-label="Name">vshsbsbh</td>
      <td data-label="Mobile Number">36377588436</td>
      <td data-label="Email">vshsbsbh@gmail.com</td>
      <td data-label="Company"> Xyzabc</td>
      <td data-label="Action"> <button type="button" className="btn btn-success rounded-circle py-2 ps-3 pe-2 invitation-resend-btn" ><i className="fa fa-undo"></i></button> </td>
    </tr>
    <tr>
      <td data-label="Event Date">07-02-2022</td>
      <td data-label="Name">vshsbsbh</td>
      <td data-label="Mobile Number">36377588436</td>
      <td data-label="Email">vshsbsbh@gmail.com</td>
      <td data-label="Company"> Xyzabc</td>
      <td data-label="Action"> <button type="button" className="btn  btn-primary rounded-lg py-2 ps-3 pe-2 " > Attended </button> </td>
    </tr>
  </tbody>
</table>
<ResetDateChapter />
    </div>


    </>

  )
}


export default InvitationHistory
