import React from 'react'
import {Button, Image, Tab, Tabs} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {AskForReferral} from './modal/AskForReferral'
import {BusinessReferral} from './modal/BusinessReferral'
import {BusinessConnect} from './modal/BusinessConnect'
import {GratitudeForBusiness} from './modal/GratitudeForBusiness'
import {MakeAnnoucement} from './modal/MakeAnnoucement'
import {ShareIdeasModal} from './modal/ShareIdeasModal'
import {SayThankYou} from './modal/SayThankYou'
import {InviteVisitor} from './modal/InviteVisitor'
import {GroupMeetingReport} from './modal/GroupMeetingReport'
import { SubmitTracksheet } from './modal/SubmitTracksheet'
import { SubmitMRTracking } from './modal/SubmitMRTracking'
import { ManageGoal } from './modal/ManageGoal'

export function WritePostModal() {
  return (
    <>
      {/* begin: Write Post Button */}
      <div className='w-100 d-flex justify-content-end ms-0 ms-md-7'>
        <div className='add_post_button float-right'>
          <Button
            variant='link'
            className='p-0'
            data-bs-toggle='modal'
            data-bs-target='#write_post_modal'
          >
            <Image src={toAbsoluteUrl('/media/ilead/add-post-icon.svg')} className='w-55px'></Image>
          </Button>
        </div>
      </div>
      {/* end: Write Post Button */}
      {/* begin: Write Post Modal */}
      <div
        className='modal fade ilead-default-modal'
        id='write_post_modal'
        aria-hidden='true'
        aria-labelledby='writePostModalToggleLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='writePostModalToggleLabel'>
                Write post to
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body write-post-wrapper px-5'>
              <Tabs
                defaultActiveKey='business'
                id='fill-tab-example'
                className='mb-3 writepost-btn-group'
                fill
              >
                <Tab eventKey='business' title='Business'>
                  <div className='d-flex flex-column'>
                    {/* begin: Ask for referral button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#AskforReferrralModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/AskforReferral.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Ask for Referral
                    </Button>
                    {/* end: Ask for referral button */}
                    {/* begin: Business Referral button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#BusinessReferrralModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/BusinessReferral.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Business Referral
                    </Button>
                    {/* end: Business Referral button */}
                    {/* begin: Business Connect button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#BusinessConnectModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/BusinessConnect.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Business Connect
                    </Button>
                    {/* end: Business Connect button */}
                    {/* begin: Gratitude for bussion button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#GratitudeForBusinessModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/GratitudeforBusiness.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Gratitude for Business
                    </Button>
                    {/* end: Gratitude for bussion button */}
                  </div>
                </Tab>
                <Tab eventKey='commune' title='Commune'>
                  <div className='d-flex flex-column'>
                    {/* begin: Make Annoucement button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#MakeAnnoucementModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/MakeAnnouncement.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Make Announcement
                    </Button>
                    {/* end: Make Annoucement button */}
                    {/* begin: Share Ideas button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#ShareIdeasModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/Idea.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Share Ideas / Success / Learning / Events
                    </Button>
                    {/* end: Share Ideas button */}
                    {/* begin: Say Thank You button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#SayThankYouModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/GratitudeforBusiness.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Say Thank You
                    </Button>
                    {/* end: Say Thank You button */}
                    {/* begin: Invite Visitor button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#InviteVisitorModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/InviteVisitor.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Invite Visitor
                    </Button>
                    {/* end: Invite Visitor button */}
                    {/* begin: Submit Group Meeting Report button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#SubmitGroupMeetingReportModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/GroupMeeting.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Submit Group Meeting Report
                    </Button>
                    {/* end: Submit Group Meeting Report button */}
                  </div>
                </Tab>
                <Tab eventKey='goals' title='Goals'>
                  <div className='d-flex flex-column'>
                    {/* begin: Submit Tracksheet button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#SubmitTracksheetModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/SubmitTracksheet.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Submit Tracksheet
                    </Button>
                    {/* end: Submit Tracksheet button */}
                    {/* begin: Submit MR */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target='#SubmitMRTrackingModal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/SubmitMR.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Submit MR
                    </Button>
                    {/* end: Submit MR */}
                    {/* begin: Manage Goals button */}
                    <Button
                      variant='link'
                      className='writepost-button w-100 mb-3 text-start d-flex align-items-center'
                      data-bs-target="#ManageGoalModal"
                      data-bs-toggle="modal" 
                      data-bs-dismiss="modal" 
                      
                    >
                      <Image
                        src={toAbsoluteUrl('/media/ilead/ManageGoals.svg')}
                        className='w-25px me-3'
                      ></Image>
                      Manage Goals
                    </Button>
                    {/* end: Manage Goals button */}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {/* end: Write Post Modal */}
      {/* begin: Ask for Help Modal */}
      <AskForReferral></AskForReferral>
      {/* end: Ask for Help */}
      {/* begin: Business Referral Modal */}
      <BusinessReferral></BusinessReferral>
      {/* end: Business Referral Modal */}
      {/* begin: Business Connect Modal */}
      <BusinessConnect></BusinessConnect>
      {/* end: Business Connect Modal */}
      {/* begin: Gratitude for Business Modal */}
      <GratitudeForBusiness></GratitudeForBusiness>
      {/* end: Gratitude for Business Modal */}
      {/* begin: Make Annoucement Modal */}
      <MakeAnnoucement></MakeAnnoucement>
      {/* end: Make Annoucement Modal */}
      {/* begin: Share Idea Modal */}
      <ShareIdeasModal></ShareIdeasModal>
      {/* end: Share Idea Modal */}
      {/* begin: Say Thank You Modal */}
      <SayThankYou></SayThankYou>
      {/* end: Say Thank You Modal */}
      {/* begin: Invite Visitor Modal */}
      <InviteVisitor></InviteVisitor>
      {/* end: Invite Visitor Modal */}
      {/* begin: Submit Group Meeting Reports Modal */}
      <GroupMeetingReport></GroupMeetingReport>
      {/* end: Submit Group Meeting Reports Modal */}
      {/* begin: Submit Tracksheet Modal */}
      <SubmitTracksheet></SubmitTracksheet>
      {/* end: Submit Tracksheet Modal */}
      {/* begin: Submit MR Modal */}
      <SubmitMRTracking></SubmitMRTracking>
      {/* end: Submit MR Modal */}
      <ManageGoal></ManageGoal>
    </>
  )
}
