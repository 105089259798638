import React from 'react'
import {Button, Form} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'

export function SubmitTracksheet() {
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='SubmitTracksheetModal'
        aria-hidden='true'
        aria-labelledby='SubmitTracksheetModalLabel'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='SubmitTracksheetModalToggleLabel'>
                Submit Tracksheet
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              <Form className='mb-7'>
                <Form.Group className='mb-3 mt-7'>
                  <h5 className='mt-7 mb-7'>Add Attachment</h5>
                  <Button variant='primary' className='me-3 px-4 py-3'>
                    <i className='bi bi-camera-fill pe-0' style={{fontSize: '20px'}}></i>
                  </Button>
                  <div className='me-3 px-4 py-3 position-relative btn btn-primary'>
                    <Form.Control
                      type='file'
                      className='position-absolute w-100 h-100 start-0 top-0 opacity-0'
                    ></Form.Control>
                    <i className='bi bi-image-fill pe-0' style={{fontSize: '20px'}}></i>
                  </div>
                </Form.Group>
                <Button variant='primary' type='submit' className='w-100 mt-7'>
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/* begin: Ask for Help Modal */}
    </>
  )
  document.getElementById('element')
}
