import React from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'

const ICIWFUUPL: React.FC = () => {
  return (
    <>
      <h1 className='inv-hst'>ICIWF/UUPL</h1>

      <div className='card p-8 mt-5 iciwf'>
        <h1> ILEAD Trustees </h1>

        <div className='row'>
          <div className='col-md-3 thumbnail-box'>
            <img
              src={toAbsoluteUrl('./media/avatars/Shyam-Taneja-full.jpg')}
              alt=''
              className='thumbnail-img'
            />
          </div>
          <div className='col-md-9'>
            <button className='title-name'>SHYAM TANEJA </button>
            <h6> B.Sc., BE (IIT-R); Alumnus- IIM-A </h6>

            <hr className='my-10' />

            <p>
              Life is a process of Value Addition and Fulfilment. It is an Opportunity to Evolve to
              higher levels of Consciousness. The abundant Potential needs to be Explored
              continuously by following the concept of <strong> "Beat Your Best" </strong>
            </p>

            <p>
              Shyam Taneja started his career with NDDB in 1971 and rose quickly from being a
              project Engineer to the GM of Hindustan Packaging Company Ltd – a joint venture
              between Tetra Pack and NDDB. He then became the Managing Director reporting to VK
              Kurien. In the year 1994, he moved to Ahmedabad as Director for Vadilal before
              choosing to become an entrepreneur and promoter.
            </p>

            <p>
              In 1998 he promoted the Unnati Group of companies with a mission to support
              individuals and organizations in exploring their unlimited potential to achieve both
              success and fulfillment in life
            </p>
          </div>
        </div>

        <div className='row mt-20'>
          <div className='col-md-3 thumbnail-box'>
            <img
              src={toAbsoluteUrl('./media/avatars/MS_NEERU_GUPTA.jpg')}
              alt=''
              className='thumbnail-img'
            />
          </div>
          <div className='col-md-9'>
            <button className='title-name'> NEERU GUPTA </button>
            <h6> B.E., MBA </h6>

            <hr className='my-10' />

            <p>
              Neeru brings over 20 years of experience in learning and development spanning areas
              such as managing learning academies for organizations, identifying training needs,
              designing training modules, facilitating personal effectiveness programs and coaching
              individuals and teams. In her earlier roles, she has led McKinsey India’s Learning
              function and has also worked as a senior consultant in the OD field with Usha
              Consulting.
            </p>

            <p>
              She is now Director of Unlimited Unnati focussing of Coaching & Leadership Development
            </p>
          </div>
        </div>

        <div className='row mt-20'>
          <div className='col-md-3 thumbnail-box'>
            <img
              src={toAbsoluteUrl('./media/avatars/MS_SAMA_TANEJA.jpg')}
              alt=''
              className='thumbnail-img'
            />
          </div>
          <div className='col-md-9'>
            <button className='title-name'> SAMA TANEJA </button>
            <h6> B.Com, MBA (Symbiosis, Pune) </h6>

            <hr className='my-10' />

            <p>
              In 14 years of her corporate career, Sama has worked across sectors - IT, KPO,
              Pharmaceuticals and Foods & Beverages and has the unique experience blend of Industry
              and Consulting in HR and Social Innovation . She has held HR leadership roles in the
              last four organizations and has been part of the executive leadership team reporting
              to the Head of Business. She is now Director of Unlimited Unnati focussing of business
              coaching for MSME and SMEs in India She is a certified MBTI facilitator and a Business
              Coach
            </p>
          </div>
        </div>

      </div>

      <div className='card p-8 mt-5 iciwf'>
        <h1> ILEAD Secretariat </h1>
        <div className='row justify-content-center mt-6'>
          <div className='col-md-6 defaultuser'>
          <div className='thumbnail-box text-center'>
            <img
              src={toAbsoluteUrl('./media/avatars/default-user.png')}
              alt=''
              className='thumbnail-img'
            />

          </div>
           <p className='mt-3 fw-semibold'> Vaibhav Joshi </p>
            <p> <i className="fa fa-phone"></i> +919909001479 </p>
            <p> <i className="fa fa-envelope"></i> 
            <Link to='#' className='ml-3 text-dark'> vaibhav@icaniwill... 
            </Link></p>
        </div>
        </div>
        </div>

        <div className='card p-8 mt-5 iciwf'>
        <h1> ILEAD Directors </h1>
        <div className='row  mt-20'>
          <div className='col-md-3 defaultuser'>
          <div className='thumbnail-box text-center'>
            <img
              src={toAbsoluteUrl('./media/avatars/Akash-Goyal.jpeg')}
              alt=''
              className='thumbnail-img'
            />

          </div>
           <p className='mt-3 fw-semibold'> Akash Goyal </p>
           <p className='mt-3 fw-bold uppercase'> ILEAD </p>
            <p> <i className="fa fa-phone"></i> +919909001479 </p>
            <p> <i className="fa fa-envelope"></i> 
            <Link to='#' className='ml-3 text-dark'> vaibhav@icaniwill... 
            </Link></p>
        </div>
        <div className='col-md-3 defaultuser'>
          <div className='thumbnail-box text-center'>
            <img
              src={toAbsoluteUrl('./media/avatars/default-user.png')}
              alt=''
              className='thumbnail-img'
            />

          </div>
           <p className='mt-3 fw-semibold'> Hemant Singh </p>
           <p className='mt-3 fw-bold uppercase'> VLEAD </p>
            <p> <i className="fa fa-phone"></i> +919909001479 </p>
            <p> <i className="fa fa-envelope"></i> 
            <Link to='#' className='ml-3 text-dark'> vaibhav@icaniwill... 
            </Link></p>
        </div>
        <div className='col-md-3 defaultuser'>
          <div className='thumbnail-box text-center'>
            <img
              src={toAbsoluteUrl('./media/avatars/default-user.png')}
              alt=''
              className='thumbnail-img'
            />

          </div>
           <p className='mt-3 fw-semibold'> Shyju Siddharthan  </p>
           <p className='mt-3 fw-bold uppercase'> LIMA </p>
            <p> <i className="fa fa-phone"></i> +919909001479 </p>
            <p> <i className="fa fa-envelope"></i> 
            <Link to='#' className='ml-3 text-dark'> vaibhav@icaniwill... 
            </Link></p>
        </div>
        <div className='col-md-3 defaultuser'>
          <div className='thumbnail-box text-center'>
            <img
              src={toAbsoluteUrl('./media/avatars/default-user.png')}
              alt=''
              className='thumbnail-img'
            />

          </div>
           <p className='mt-3 fw-semibold'> Swaty Gosar </p>
           <p className='mt-3 fw-bold uppercase'> BeHa </p>
            <p> <i className="fa fa-phone"></i> +919909001479 </p>
            <p> <i className="fa fa-envelope"></i> 
            <Link to='#' className='ml-3 text-dark'> vaibhav@icaniwill... 
            </Link></p>
        </div>
        </div>
        </div>
    </>
  )
}

export default ICIWFUUPL
