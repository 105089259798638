import React, {useState, useEffect} from 'react'
import {toAbsoluteUrl} from './_metronic/helpers'
import {Image} from 'react-bootstrap'

const NoInternetConnection = (props: any) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true)

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true)
  })

  window.addEventListener('offline', () => {
    setOnline(false)
  })

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children
  } else {
    return <div className='text-center'>
      <Image src={toAbsoluteUrl('/media/ilead/nointernet.jpg')} className='w-500px' fluid></Image>
      <h1 className='text-gray-900 display-1'>Ooops!</h1>
      <p className='fs-4 text-gray-500'>Please check your internet connection</p>
    </div>
  }
}

export default NoInternetConnection
