import React, {useState} from 'react'
import {Button, Form, InputGroup} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import {SearchLeaderListing} from './SearchLeaderListing'

export function InviteVisitor() {
  const [searchLeaderListing, setsearchLeaderListing] = useState(true)
  return (
    <div
      className='modal fade ilead-default-modal'
      id='InviteVisitorModal'
      aria-hidden='true'
      aria-labelledby='InviteVisitorModallLabel'
    >
      <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
        <div className='modal-content'>
          <div className='modal-header py-5'>
            <h3 className='modal-title fs-3 fw-bold' id='InviteVisitorModallLabelToggleLabel'>
              Invite Visitor
            </h3>
            <button
              type='button'
              className='btn-close text-white'
              data-bs-target='#write_post_modal'
              data-bs-toggle='modal'
              data-bs-dismiss='modal'
            ></button>
          </div>
          <div className='modal-body pt-7 write-post-wrapper'>
            {searchLeaderListing ? (
              <>
                <Form className='mb-7'>
                  <Form.Label className='text-gray-700 mb-7'>
                    Use Below Form To Send An Invitation To Visitor.
                  </Form.Label>
                  <Form.Group className='mb-4'>
                    <Form.Select aria-label='select chapter' className='ilead-input'>
                      <option>Select Chapter</option>
                      <option value='eklavya'>Eklavya</option>
                      <option value='beta'>Beta</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Select aria-label='select event' className='ilead-input'>
                      <option>Select Event Date</option>
                      <option value='1'>21-7-2023</option>
                      <option value='2'>Name</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <InputGroup className='mb-2'>
                      <Form.Control
                        placeholder='Name'
                        aria-label='name'
                        id='name'
                        className='border-right-0 ilead-input'
                      />
                      <InputGroup.Text id='name' className='bg-white ilead-input-group'>
                        <i className='fa-solid fa-address-book' style={{fontSize: '18px'}}></i>
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Control
                      type='number'
                      id='mobileNumber'
                      className='ilead-input mb-2'
                      placeholder='Mobile Number'
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Control
                      type='email'
                      id='email'
                      className='ilead-input'
                      placeholder='Email'
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Control
                      type='text'
                      id='companyName'
                      className='ilead-input'
                      placeholder='Company Name'
                    />
                  </Form.Group>
                  <Form.Group className='mt-5 d-flex'>
                    <Button variant='primary' type='submit' className='w-100'>
                      Send Invitation
                    </Button>
                  </Form.Group>
                </Form>
              </>
            ) : (
              <SearchLeaderListing onClick={setsearchLeaderListing}></SearchLeaderListing>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
