import React, {useState} from 'react'
import {Form} from 'react-bootstrap'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function FilterDropdown() {
  const [loading, setLoading] = useState(false)

  const click = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px mobile-filter'
      data-kt-menu='true'
    >
      <div className='bg-white'>
        <div className='px-7 py-5 d-flex justify-content-between align-items-center'>
          <div className='fs-5 text-dark fw-bolder'>Filter Post</div>
          <div className='cursor-pointer' data-kt-menu-dismiss='true'>
            <i className='bi bi-x-lg fs-3'></i>
          </div>
        </div>

        <div className='separator border-gray-300'></div>

        <div className='px-7 py-5 filter-scroll-area'>
          <Form.Check
            label='Announcement'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='announcement'
          />
          <Form.Check
            label='Help / Referral'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='help'
          />
          <Form.Check
            label='Share Idea'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='shareIdea'
          />
          <Form.Check
            label='Thank You'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='thankYou'
          />
          <Form.Check
            label='Business Referral'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='businessReferral'
          />
          <Form.Check
            label='Tracksheet'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='tracksheet'
          />
          <Form.Check
            label='Invite Visitor'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='inviteVisitor'
          />
          <Form.Check
            label='Feedback'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='feedback'
          />
          <Form.Check label='TYCA' name='postfilter' type='radio' className='mb-5' id='tyca' />
          <Form.Check
            label='Group Meeting Report'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='groupMeetingReport'
          />
          <Form.Check
            label='Session Flow'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='sessionFlow'
          />
          <Form.Check
            label='TYCA Question'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='tycaQuestion'
          />
          <Form.Check
            label='Bookmarked Post'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='bookmarkedPost'
          />
          <Form.Check
            label='Business Connect'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='businessConnect'
          />
          <Form.Check
            label='Business Generated'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='businessGenerated'
          />
          <Form.Check
            label='MR Tracking'
            name='postfilter'
            type='radio'
            className='mb-5'
            id='mrTracking'
          />
        </div>
        <div className='filter-button py-5 px-7'>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-light btn-active-light-primary me-2 w-100'
              data-kt-menu-dismiss='true'
            >
              CLEAR
            </button>
            <button onClick={click} className='btn btn-primary w-100'>
              {!loading && 'FILTER'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
