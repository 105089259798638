import React, {useEffect, useState} from 'react'
import {PostHeader} from './PostHeader'
import {PostFooter} from './PostFooter'
import {Carousel, Image} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ThankYouPost} from './ThankYouPost'
import {DocumentPost} from './DocumentPost'
import {BirthDayPost} from './BirthDayPost'
import Zoom from 'react-medium-image-zoom'

export function DefaultPost() {
  const [showDiv, setShowDiv] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleScroll = () => {
    const scrollY = window.scrollY
    setShowDiv(scrollY >= 1000)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleDivClick = () => {
    setIsLoading(true)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      setIsLoading(false)
    }, 1000)
  }

  return (
    <>
      <div className='w-100 d-flex justify-content-center'>
        {showDiv && (
          <div className='show-after-scroll new-post-added cursor-pointer' onClick={handleDivClick}>
            {isLoading ? <div className='loader'>Loading...</div> : <div><i className="fa-solid fa-arrow-up me-2 text-white"></i> New post added</div>}
          </div>
        )}
      </div>
      {/* begin: Birthday Post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader posttypebirthdaypost={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <BirthDayPost></BirthDayPost>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* end:Birthday Post */}
      {/* begin:Annoucement post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader pinned={true} poststypeannoucement={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            I Can I Will Leader
            <br />
            <br />
            Our VLEAD initiative is successful with your support & cooperation,
            <br />
            I would like to plan to Start "ICanIWill" program's 1st batch of Basic for your
            teammates,
            <br /> So that You & your company grow faster & the Quality of Life of all becomes
            better.
            <br />
            <br /> We are going to start VLEAD Basic as per below.
            <br /> Basic: starts from 13th July 2023 Thursday
            <br />
            <br /> Time: 4 pm to 6 pm
            <br />
            Venue: Zoom
            <br />
            <br /> For more details connect to Akash on 9909001479
            <br />
            <br />
            Thanks for your support in advance
            <br />
            <br />V CAN V WILL
          </div>
        </div>
        <PostFooter alloption={true}></PostFooter>
      </div>
      {/* end:post */}
      {/* begin:Help Referal post post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypehelp={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            I’m trying to leverage technology for attaining goals however I’m not technology lover
            nor gadget freak. Anyone can pls help me answer few queries on right technology setup
            for my purpose? Quick 15-20mins call will do. Thanks so much in advance🙏🏻
          </div>
        </div>
        <PostFooter alloption={true}></PostFooter>
      </div>
      {/* end:post */}
      {/* begin:Ideas/Achievement post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypeshareidea={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            No day is more appropriate than today for us to express our gratitude for all the
            knowledge you have imparted to all of us. ...
            <br />
            <br />
            Heartfelt Gratitude to Shyam Sir, Sama Ma’am, Neeru Ma’am & Anil Sir
            <br />
            <br />
            We Can We Will 🙏
          </div>
          <div className='media mb-2'>
            <Carousel controls={false}>
              <Carousel.Item>
                <Image
                  src={toAbsoluteUrl('media/stock/600x400/img-3.jpg')}
                  className='d-block w-100'
                ></Image>
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  src={toAbsoluteUrl('media/stock/600x400/img-1.jpg')}
                  className='d-block w-100'
                ></Image>
              </Carousel.Item>
              <Carousel.Item>
                <Image
                  src={toAbsoluteUrl('media/stock/600x400/img-2.jpg')}
                  className='d-block w-100'
                ></Image>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <PostFooter share={true} option={false}></PostFooter>
      </div>
      {/* end:post */}
      {/* begin:Thank You post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypethankyou={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <ThankYouPost></ThankYouPost>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* end:post */}
      {/* begin:Business Referral post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypebusinessreferral={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <ThankYouPost businessrefrral={true}></ThankYouPost>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* begin:Tracksheet post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypetracksheet={true} grouppost={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            Tracksheet as on Date 04/07/2023
          </div>
          <div className='media mb-2'>
            <Zoom>
            <Image
              src={toAbsoluteUrl('media/ilead/tracksheetupdated.jpg')}
              className='d-block w-100'
            ></Image>
            </Zoom>
          </div>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* begin:Visitor Invitation post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypeinvitevisitor={true} grouppost={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            Gaurav Shah Invited Prem for Session Dated Friday, April 3rd 2023
          </div>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* end:post */}
      {/* begin:TYCA Presentation Updates post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypetyca={true} grouppost={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <DocumentPost></DocumentPost>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* end:post */}
      {/* begin:TYCA Presentation Updates post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypegroupmeetingreport={true} privatepost={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            Leaders of Beta 1 Had Group Meeting On 2021-11-16.
            <br />
            Summary : Reviewed Goals shared Diwali 🪔 wishes and experiences celebrated 🏏🏏🎉🎉
            Ldrs Hardik Patel Man of the Match Moments !! Ldrs attended Rajendra Patil , Hardik
            Panchal , Hardik Patel & Jitendra Mehta dt. 9.11.21 time 8 am to 8:35am .
          </div>
          <div className='media mb-2'>
            <Zoom>
            <Image
              src={toAbsoluteUrl(
                'https://api.ileadsampark.org/api/containers/topicimages/download/1637068396111_1637068349803.jpg'
              )}
              className='d-block w-100'
            ></Image>
            </Zoom>
          </div>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* end:post */}
      {/* begin:TYCA Question post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypetycaquestion={true} privatepost={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            TYCA questions for Lr. Darshak Prajapati from Group Beta 1
          </div>
          <div className='media mb-2'>
            <Image
              src='https://api.ileadsampark.org/api/containers/topicimages/download/1667802419271_1667802411317.jpg'
              className='d-block w-100'
            ></Image>
          </div>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* begin:MR Tracking post */}
      <div className='card card-flush mb-4 post-card'>
        <PostHeader poststypetycaquestion={true} privatepost={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            Lr. Darshak Prajapati reached milestone of 0 for the goal of 10. The goal is read book
            of pages.
          </div>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* begin:Hide Post */}
      <div className='card card-flush mb-4 post-card hide-post'>
        <PostHeader poststypetycaquestion={true} privatepost={true}></PostHeader>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='fs-7 fs-md-6 fw-normal text-black mb-5 px-4'>
            Lr. Darshak Prajapati reached milestone of 0 for the goal of 10. The goal is read book
            of pages.
          </div>
        </div>
        <PostFooter share={true} option={true}></PostFooter>
      </div>
      {/* begin:No Post Found post */}
      <div className='card card-flush mb-4 post-card'>
        <div className='card-body pt-3 pb-3 ps-0 pe-0'>
          <div className='media' style={{maxHeight: 'inherit'}}>
            <Image src={toAbsoluteUrl('media/ilead/no-post.png')} className='d-block w-100'></Image>
          </div>
        </div>
      </div>
      {/* end:post */}
    </>
  )
}
