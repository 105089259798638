import React, {useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import TextareaAutosize from 'react-textarea-autosize'
import {SearchLeaderListing} from './SearchLeaderListing'

export function GratitudeForBusiness() {
  const [searchLeaderListing, setsearchLeaderListing] = useState(true)
  return (
    <>
      <div
        className='modal fade ilead-default-modal'
        id='GratitudeForBusinessModal'
        aria-hidden='true'
        aria-labelledby='GratitudeForBusinessModal'
      >
        <div className='modal-dialog modal-dialog modal-dialog-scrollable modal-fullscreen-md-down modal-md'>
          <div className='modal-content'>
            <div className='modal-header py-5'>
              <h3 className='modal-title fs-3 fw-bold' id='GratitudeForBusinessModalToggleLabel'>
                Gratitude for Business
              </h3>
              <button
                type='button'
                className='btn-close text-white'
                data-bs-target='#write_post_modal'
                data-bs-toggle='modal'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body pt-7 write-post-wrapper'>
              {searchLeaderListing ? (
                <Form className='mb-7'>
                  <h3 className='text-black fw-bold fs-4 mb-6'>Gratitude For Leader</h3>
                  <Form.Group className='mb-4'>
                    <Form.Label
                      className='fs-14 border-bottom w-100 py-2 text-gray-700'
                      onClick={() => {
                        setsearchLeaderListing(false)
                      }}
                    >
                      Select leader name
                    </Form.Label>
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label className='w-100 py-2 text-gray-900 fw-bold'>
                      Business Generated of
                    </Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='Enter Amount'
                      aria-label='amount'
                      id='amount'
                      className='border-right-0 ilead-input'
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <Form.Label className='w-100 py-2 text-gray-900 fw-bold'>
                      For Product/Service
                    </Form.Label>
                    <TextareaAutosize
                      minRows={3}
                      autoFocus
                      className='custom-text-area form-control fw-normal ilead-input'
                      placeholder='Enter product/service'
                    />
                  </Form.Group>
                  <Form.Group className='mt-5 d-flex'>
                    <Button
                      variant='secondary'
                      type='button'
                      className='w-50 me-3'
                      data-bs-target='#write_post_modal'
                      data-bs-toggle='modal'
                      data-bs-dismiss='modal'
                    >
                      Close
                    </Button>
                    <Button variant='primary' type='submit' className='w-50'>
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
              ) : (
                <SearchLeaderListing onClick={setsearchLeaderListing}></SearchLeaderListing>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
